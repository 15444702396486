import { TagField } from '@venncity/block';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { useAsyncList } from 'react-stately';
import type { ProductResourceDTO } from '~/dto/product-resource-dto';

interface ProductsTagFieldProps {
  defaultValueFull?: ProductResourceDTO[];
  placeholder?: string;
  name?: string;
  errorMessage?: string;
}

export default function ProductsTagField({
  placeholder = 'Choose products',
  name = 'products',
  defaultValueFull,
  errorMessage,
}: ProductsTagFieldProps) {
  const productsList = useAsyncList<ProductResourceDTO>({
    async load({ filterText }) {
      const { data } = await axios.get(`/resources/products`, {
        params: { q: filterText },
      });
      return { items: data || [] };
    },
  });

  return (
    <TagField
      defaultSelectedItems={defaultValueFull}
      errorMessage={errorMessage}
      getItemId={(item) => item.id}
      getItemLabel={(item) => item.name || ''}
      items={productsList.items}
      name={name}
      onInputChange={debounce(productsList.setFilterText, 400, {
        maxWait: 1000,
        leading: false,
        trailing: true,
      })}
      placeholder={placeholder}>
      {(item) => <span>{item.name}</span>}
    </TagField>
  );
}
