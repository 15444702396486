import { TagField } from '@venncity/block';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { useAsyncList } from 'react-stately';
import type { ServiceResourceDTO } from '~/dto/service-resource-dto';

interface ServicesTagFieldProps {
  defaultValueFull?: ServiceResourceDTO[];
  placeholder?: string;
  name?: string;
  errorMessage?: string;
}

export default function ServicesTagField({
  placeholder = 'Choose services',
  name = 'services',
  defaultValueFull,
  errorMessage,
}: ServicesTagFieldProps) {
  const servicesList = useAsyncList<ServiceResourceDTO>({
    async load({ filterText }) {
      const { data } = await axios.get(`/resources/services`, {
        params: { q: filterText },
      });
      return { items: data || [] };
    },
  });

  return (
    <TagField
      defaultSelectedItems={defaultValueFull}
      errorMessage={errorMessage}
      getItemId={(item) => item.id}
      getItemLabel={(item) => item.name || ''}
      items={servicesList.items}
      name={name}
      onInputChange={debounce(servicesList.setFilterText, 400, {
        maxWait: 1000,
        leading: false,
        trailing: true,
      })}
      placeholder={placeholder}>
      {(item) => <span>{item.name}</span>}
    </TagField>
  );
}
