import { IconName } from '@venncity/block';
import { useTranslation } from 'react-i18next';
import { useAppLoaderData } from '~/utils/common';

interface ModuleItem {
  id: string;
  icon: IconName;
  label: string;
  tag?: string;
  visible?: boolean;
}

export function useAvailableHubModules(includeDisabled?: boolean): ModuleItem[] {
  const appLoaderData = useAppLoaderData();
  const { t } = useTranslation('app-layout');

  const packagesFeatureFlags = appLoaderData.featureFlags.hub_my_packages;
  const renewalsFeatureFlags = appLoaderData.featureFlags.hub_renewal;
  const shouldShowAwayNotice = false; //DOES NOT EXIST so assume always false -> props?.buildingSettings?.isAwayNoticeEnabled;
  const shouldShowPreLease = appLoaderData.featureFlags.hub_pre_lease;
  const shouldShowServiceRequests = appLoaderData.isMaintenanceDashboardEnabled;
  const shouldShowShiftNotes = appLoaderData?.buildingSettings?.isShiftNotesEnabled;
  const shouldShowVisitors = appLoaderData?.buildingSettings?.isVisitorsEnabled;
  const shouldShowWorkflows = appLoaderData.featureFlags.hub_workflows;

  const allModules: ModuleItem[] = [
    {
      id: 'move-in',
      icon: 'movein',
      label: t('sideMenu.moveIn'),
    },
    {
      id: 'communication',
      icon: 'communication',
      label: t('sideMenu.communication'),
    },
    {
      id: 'stay',
      icon: 'community',
      label: t('sideMenu.Community'),
    },
    {
      id: 'insights',
      icon: 'spark_stroke',
      label: t('sideMenu.insights'),
    },
    {
      id: 'applications',
      icon: 'agreement',
      label: 'Applications & Leases',
      visible: shouldShowPreLease,
    },
    {
      id: 'amenities/reservations',
      icon: 'amenities',
      label: t('sideMenu.amenities'),
    },
    {
      id: renewalsFeatureFlags ? 'renewals' : 'renewals/coming-soon',
      icon: 'renewals',
      label: t('sideMenu.renewals'),
    },
    {
      id: 'residents',
      icon: 'residents',
      label: t('sideMenu.residents360'),
      tag: t('sideMenu.BETA'),
    },
    {
      id: 'mbr',
      icon: 'reports',
      label: t('sideMenu.reports'),
    },
    {
      id: 'csat',
      icon: 'reports',
      label: t('sideMenu.csat'),
    },
    {
      id: 'workflows',
      icon: 'star',
      label: t('sideMenu.workflows'),
      visible: shouldShowWorkflows,
    },
    {
      id: 'packages',
      icon: 'package',
      label: t('sideMenu.packages'),
      visible: packagesFeatureFlags,
    },
    {
      id: 'visitors',
      icon: 'visitor',
      label: t('sideMenu.visitors'),
      visible: shouldShowVisitors,
    },
    {
      id: 'away-notices',
      icon: 'plant-2',
      label: t('sideMenu.awayNoticeDashboard'),
      visible: shouldShowAwayNotice,
    },
    {
      id: 'shift-notes',
      icon: 'file-plus',
      label: t('sideMenu.shiftNotesDashboard'),
      visible: shouldShowShiftNotes,
    },
    {
      id: 'service-requests',
      icon: 'maintenance',
      label: t('sideMenu.maintenanceDashboard'),
      visible: shouldShowServiceRequests,
    },
  ];

  if (includeDisabled) {
    return allModules;
  }

  return allModules.filter((module) => module.visible !== false);
}
