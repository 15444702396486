import { useSearchParams, useFetcher, useFetchers } from 'react-router';
import {
  DatePicker,
  Heading,
  Item,
  NumberField,
  Select,
  Text,
  TextField,
  RichText,
  Dialog,
  TagField,
  Icon,
  ClickableTooltipTrigger,
  Tooltip,
} from '@venncity/block';
import { Button, Switch, Divider } from '@venncity/venn-ds';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { compact, isEmpty } from 'lodash-es';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValueOf } from 'type-fest';
import AsyncCombobox from '~/components/async-combo-box';
import AsyncTagField from '~/components/async-tag-field';
import { ImageUpload } from '~/components/image-upload';
import type { BuildingResourceDTO } from '~/dto/building-resource-dto';
import {
  EventFormAudienceType,
  type EventFormDTO,
  EventCategoryType,
  AdmissionFeeType,
  EventFormAction,
  EventFormFields,
} from '~/dto/event-form-dto';
import type { PlaceResourceDTO } from '~/dto/place-resource-dto';
import type { UserResourceDTO } from '~/dto/user-resource-dto';
import type { Community } from '~/genql';
import { enumAdmissionFeeType } from '~/genql';
import { useAppLoaderData } from '~/utils/common';
import useScrollToError from '~/utils/hooks/useScrollToError';
import type { ActionResponseBody } from '~/utils/http.server';
import { GlobalSearchParam, useSearchNavigate, useSearchNavigation } from '~/utils/search-params';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

interface EventFormProps {
  initialValues?: EventFormDTO;
  eventId?: string;
  title: string;
}

const ADMISSION_FEE_TYPE_ITEMS = [
  {
    id: AdmissionFeeType.None,
    type: enumAdmissionFeeType.NONE,
    label: 'None',
  },
  {
    id: AdmissionFeeType.Free,
    type: enumAdmissionFeeType.FREE,
    label: 'Free',
  },
  {
    id: AdmissionFeeType.Complimentary,
    type: enumAdmissionFeeType.COMPLIMENTARY,
    label: 'Complimentary from management',
  },
];

export function EventForm({ initialValues, title }: EventFormProps) {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();
  const searchNavigation = useSearchNavigation();
  const fetcher = useFetcher<ActionResponseBody<{ id: string }>>();
  const fetchers = useFetchers();
  const dialogRef = React.useRef<HTMLElement>(null);
  const { t } = useTranslation('event-form');
  const appLoaderData = useAppLoaderData();
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

  const errors = fetcher.data?.errors;

  const endDate = searchParams.get(EventFormFields.EndDate) || initialValues?.endDate;
  const name = initialValues?.name || '';
  const description = initialValues?.description || '';
  const category = initialValues?.category || EventCategoryType.Community;
  const ticketMax = initialValues?.ticketMax || 0;
  const place = initialValues?.place || '';
  const virtualLink = initialValues?.virtualLink || '';
  const buildings = initialValues?.buildings || [];
  const isPublished = initialValues?.isPublished || false;
  const timezone = appLoaderData.selectedCommunity.timezone;
  const admissionFeeType = initialValues?.admissionFeeType || enumAdmissionFeeType.NONE;

  const startDate = searchParams.get(EventFormFields.StartDate) || initialValues?.startDate;

  const isResidentHosted =
    searchParams.get(EventFormFields.ResidentHosted) == null
      ? initialValues?.isResidentHosted
      : Boolean(Number(searchParams.get(EventFormFields.ResidentHosted))) || false;

  const shouldGenerateQRCode =
    Boolean(Number(searchParams.get(EventFormFields.ShouldGenerateQRCode))) ||
    initialValues?.shouldGenerateQRCode;

  const residentHosts = initialValues?.residentHosts || [];

  const audience =
    searchParams.get(EventFormFields.Audience) ||
    initialValues?.audience ||
    EventFormAudienceType.EntirePortfolio;

  const eventId = searchParams.get('event-id');

  const anyFetchersArePending = fetchers.some((fetcher) =>
    ['loading', 'submitting'].includes(fetcher.state),
  );

  const communityIds =
    initialValues?.communityIds || compact(searchParams.getAll(GlobalSearchParam.CommunityId));

  const isSavingDraft =
    anyFetchersArePending &&
    fetcher.formData?.get(EventFormFields.Action) === EventFormAction.Draft &&
    fetcher.formMethod === 'POST';

  const isPublishing =
    anyFetchersArePending &&
    fetcher.formData?.get(EventFormFields.Action) === EventFormAction.Publish &&
    fetcher.formMethod === 'POST';

  const isPreviewing =
    anyFetchersArePending &&
    fetcher.formData?.get(EventFormFields.Action) === EventFormAction.Preview &&
    fetcher.formMethod === 'POST';

  const defaultCommunity = appLoaderData.user.managedCommunities.filter((c) =>
    communityIds.includes(c.id),
  );

  const [communitiesSelected, setCommunitiesSelected] = useState<Community[]>([]);

  const communitiesSearchParams = !communitiesSelected?.length
    ? defaultCommunity?.map((c) => `community-id=${c.id}`).join('&')
    : communitiesSelected?.map((c) => `community-id=${c.id}`).join('&');

  React.useEffect(() => {
    if (fetcher.state === 'loading' && !isEmpty(fetcher.data?.data)) {
      const action = fetcher.formData?.get(EventFormFields.Action) as ValueOf<
        typeof EventFormAction
      >;

      if (action === 'preview') {
        searchNavigate(
          { view: 'preview', eventId: fetcher.data?.data?.id },
          { preventScrollReset: true },
        );
      } else {
        searchNavigate(
          {
            view: undefined,
            eventId: undefined,
            dialogType: undefined,
            residentHosted: undefined,
            [EventFormFields.StartDate]: undefined,
            [EventFormFields.EndDate]: undefined,
            [EventFormFields.TicketType]: undefined,
            [EventFormFields.Category]: undefined,
            [EventFormFields.Audience]: undefined,
            [EventFormFields.ShouldGenerateQRCode]: undefined,
            [EventFormFields.AdmissionFeeType]: undefined,
          },
          { preventScrollReset: true },
        );
      }
    }
  }, [fetcher.data, fetcher.formData, fetcher.state, searchNavigate]);

  useScrollToError(dialogRef, fetcher.data?.errors || {}, isPublishing);

  return (
    <Dialog ref={dialogRef}>
      <Dialog.Header title={title} />
      <fetcher.Form
        action={eventId ? `/resources/events/${eventId}/edit` : '/resources/events/new'}
        method="POST">
        <Dialog.Body className="pb-0">
          <Fieldset title={t('sub-title')}>
            <TextField
              defaultValue={name}
              errorMessage={errors?.name}
              label={t('event-title.label')}
              name={EventFormFields.Name}
              placeholder={t('event-title.placeholder')}
            />
            <Fieldset description={t('audience.description')} title={t('audience.title')}>
              <TagField
                defaultSelectedItems={defaultCommunity}
                errorMessage={errors?.communities}
                getItemId={(item) => item.id}
                getItemLabel={(item) => item.displayName || ''}
                hidden={!!initialValues?.id}
                items={appLoaderData.user.managedCommunities}
                label={t('community.label')}
                name="communities"
                onChange={(communities) => {
                  setCommunitiesSelected(communities as Community[]);
                }}
                placeholder={t('community.placeHolder')}>
                {(community) => <span>{community.displayName}</span>}
              </TagField>
              <Select
                defaultSelectedKey={audience}
                errorMessage={errors?.audience}
                isDisabled={searchNavigation.includes(EventFormFields.Audience)}
                label={t('audience.audience-type.label')}
                name={EventFormFields.Audience}
                onSelectionChange={(audience) => {
                  searchNavigate({ audience: audience.toString() });
                }}>
                <Item id={EventFormAudienceType.EntirePortfolio}>
                  {t('audience.audience-type.item.all')}
                </Item>
                <Item id={EventFormAudienceType.Buildings}>
                  {t('audience.audience-type.item.specific')}
                </Item>
              </Select>
              {audience === 'BUILDINGS' && (
                <AsyncTagField<BuildingResourceDTO>
                  defaultSelectedItems={buildings}
                  errorMessage={errors?.buildings}
                  getItemId={(item) => item.id}
                  getItemLabel={(item) => item.name || ''}
                  isDisabled={searchNavigation.includes(EventFormFields.Audience)}
                  key={communitiesSearchParams}
                  label={t('audience.buildings.label')}
                  name={EventFormFields.Buildings}
                  placeholder={t('audience.buildings.placeholder')}
                  resource={`buildings-community/?${communitiesSearchParams}`}>
                  {(item) => (
                    <>
                      <span>{item.name}</span>
                    </>
                  )}
                </AsyncTagField>
              )}
            </Fieldset>

            <Select
              defaultSelectedKey={category}
              errorMessage={errors?.category}
              label={t('category.label')}
              name={EventFormFields.Category}
              onSelectionChange={(selected) => {
                const category = selected?.toString();
                searchNavigate({ category });
              }}>
              <Item id={EventCategoryType.Community}>{t('category.items.community')}</Item>
              <Item id={EventCategoryType.FitnessClasses}>
                {t('category.items.fitness-classes')}
              </Item>
              <Item id={EventCategoryType.Neighborhood}>{t('category.items.neighborhood')}</Item>
            </Select>
            <RichText
              defaultValue={description}
              errorMessage={errors?.description?.[0]}
              label={t('about.label')}
              name={EventFormFields.Description}
            />
            <Select
              defaultSelectedKey={admissionFeeType}
              items={ADMISSION_FEE_TYPE_ITEMS}
              label={
                <div className="flex items-center">
                  <Text className="mr-1" slot="description">
                    {t('admission-fee.label')}
                  </Text>
                  <ClickableTooltipTrigger
                    buttonComponent={<Icon className="h-4 w-4" name="help-circle" />}
                    isOpen={isTooltipOpen}
                    setIsOpen={setIsTooltipOpen}>
                    <Tooltip className="ml-1 mt-1.5" placement="top left" variant="dark">
                      <Text
                        className="flex text-start"
                        slot="description"
                        variant="p5"
                        weight="medium">
                        {t('admission-fee.tooltip')}
                      </Text>
                    </Tooltip>
                  </ClickableTooltipTrigger>
                </div>
              }
              name={`admission-fee-type`}
              onSelectionChange={(admissionFee) => {
                searchNavigate({ 'admission-fee-type': admissionFee });
              }}>
              {ADMISSION_FEE_TYPE_ITEMS.map((item) => (
                <Item id={item.id} key={item.id}>
                  {item.label}
                </Item>
              ))}
            </Select>
            <Switch
              checked={isResidentHosted}
              id={EventFormFields.ResidentHosted}
              key={`is-resident-hosted ${Number(isResidentHosted)}`}
              label={t('hosted-by.label')}
              onChange={(residenthosted) => {
                searchNavigate({ [EventFormFields.ResidentHosted]: residenthosted });
              }}
            />
            {isResidentHosted && (
              <AsyncTagField<UserResourceDTO>
                defaultSelectedItems={residentHosts}
                errorMessage={errors?.residentHosts}
                getItemId={(item) => item.id}
                getItemLabel={(item) => item.name || ''}
                key={communitiesSearchParams}
                name={EventFormFields.ResidentHosts}
                placeholder={t('hosted-by.placeholder')}
                resource={`users/?${communitiesSearchParams}`}>
                {(item) => <span>{item.name}</span>}
              </AsyncTagField>
            )}
          </Fieldset>
          <Switch
            checked={shouldGenerateQRCode}
            id={EventFormFields.ShouldGenerateQRCode}
            key={`should-generate-QR-code ${Number(shouldGenerateQRCode)}`}
            label={t('generate-qr-code.label')}
            onChange={(shouldGenerateQRCode) => {
              searchNavigate({ [EventFormFields.ShouldGenerateQRCode]: shouldGenerateQRCode });
            }}
          />
          <Divider />
          <Fieldset
            description={t('date&time.description', {
              formatTimeZone: `(${dayjs().tz(timezone).format('z')})`,
            })}
            key={`description-${timezone}`}
            title={t('date&time.title')}>
            <DatePicker
              defaultValue={startDate}
              errorMessage={errors?.startDate}
              granularity="minute"
              key={`start-date ${timezone}`}
              label={t('date&time.startDate.label')}
              minValue={dayjs().toISOString()}
              name={EventFormFields.StartDate}
              onChange={(value) => searchNavigate({ startDate: value })}
              placeholderValue={dayjs().toISOString()}
              timezone={timezone!}
            />
            <DatePicker
              defaultValue={endDate}
              errorMessage={errors?.endDate}
              granularity="minute"
              key={`end-date ${timezone}`}
              label={t('date&time.endDate.label')}
              minValue={dayjs().toISOString()}
              name={EventFormFields.EndDate}
              placeholderValue={dayjs().toISOString()}
              timezone={timezone!}
            />
          </Fieldset>
          <Divider />
          <Fieldset description={t('upload-image.description')} title={t('upload-image.title')}>
            <ImageUpload
              defaultValue={initialValues?.image}
              description={t('upload-image.footer-description')}
              errorMessage={errors?.image}
              name={EventFormFields.Image}
            />
          </Fieldset>
          <Divider />
          <Fieldset description={t('location.description')} title={t('location.title')}>
            <AsyncCombobox<PlaceResourceDTO>
              defaultSelectedKey={place}
              errorMessage={errors?.place}
              key={communitiesSearchParams}
              label={t('location.place.label')}
              name={EventFormFields.Place}
              placeholder={t('location.place.placeholder')}
              resource={`places/?${communitiesSearchParams}`}>
              {(item) => <Item>{item.name}</Item>}
            </AsyncCombobox>
            <TextField
              defaultValue={virtualLink}
              errorMessage={errors?.virtualLink}
              label={t('location.link.label')}
              name={EventFormFields.VirtualLink}
              placeholder={t('location.link.placeholder')}
            />
          </Fieldset>
          <Divider />
          <Fieldset title={t('tickets.title')}>
            <NumberField
              defaultValue={ticketMax}
              errorMessage={errors?.maxTickets}
              isDisabled={searchNavigation.includes(EventFormFields.TicketType)}
              label={t('tickets.max.label')}
              minValue={0}
              name={EventFormFields.TicketMax}
            />
          </Fieldset>
        </Dialog.Body>
        <Dialog.Separator />
        <Dialog.Footer className="justify-between">
          <div className="flex items-center gap-x-3">
            {!isPublished && (
              <Button
                disabled={anyFetchersArePending}
                formNoValidate={true}
                htmlType="submit"
                loading={isSavingDraft}
                name={EventFormFields.Action}
                value={EventFormAction.Draft}
                variant="outlined">
                {eventId ? t('cta.update') : t('cta.save-draft')}
              </Button>
            )}
            <Button
              disabled={anyFetchersArePending}
              formNoValidate={true}
              htmlType="submit"
              loading={isPreviewing}
              name={EventFormFields.Action}
              value={EventFormAction.Preview}
              variant="outlined">
              {t('cta.preview')}
            </Button>
          </div>
          <Button
            disabled={anyFetchersArePending}
            formNoValidate={true}
            htmlType="submit"
            loading={isPublishing}
            name={EventFormFields.Action}
            type="primary"
            value={EventFormAction.Publish}>
            {t('cta.publish')}
          </Button>
        </Dialog.Footer>
      </fetcher.Form>
    </Dialog>
  );
}

function Fieldset({
  children,
  title,
  description,
  metaData,
}: {
  children?: React.ReactNode;
  title: string;
  description?: string;
  metaData?: React.ReactNode;
}) {
  return (
    <fieldset className="min-w-0">
      <header>
        <Heading variant="h3">{title}</Heading>
        {description && (
          <Text className="text-grey-700 mt-2 inline-block max-w-md">{description}</Text>
        )}
        {metaData}
      </header>
      <div className="mt-8 space-y-8">{children}</div>
    </fieldset>
  );
}
