import clsx from 'clsx';
import React from 'react';
import { RadioGroup as AriaRadioGroup, Radio as AriaRadioItem } from 'react-aria-components';
import { Icon, IconName } from '../../atoms/icon/icon';
import { Text } from '../../atoms/text/text';

export function RadioGroup({
  label,
  name,
  value,
  onChange,
  ...props
}: {
  label: string;
  value: string;
  className: string;
  name: string;
  onChange: (value: string) => void;
  children: React.ReactNode;
}) {
  return (
    <AriaRadioGroup
      {...props}
      aria-label={label}
      className="flex flex-col space-y-2"
      name={name}
      onChange={onChange}
      value={value}>
      {props.children}
    </AriaRadioGroup>
  );
}

function RadioItem({
  stringValue,
  label,
  icon,
  isDisabled,
}: {
  stringValue: string;
  label: string;
  icon?: IconName;
  isDisabled?: boolean;
}) {
  return (
    <AriaRadioItem
      className={({ isSelected }) =>
        clsx(
          'border-grey-500 w-full overflow-hidden rounded-lg border py-3 focus:outline-none',
          isSelected
            ? 'bg-grey-100 border-grey-900 text-grey-900'
            : 'border-grey-500 text-grey-500',
          isDisabled ? 'cursor-not-allowed' : 'hover:cursor-pointer',
        )
      }
      isDisabled={isDisabled}
      value={stringValue}>
      {({ isSelected, isDisabled }) => {
        const bullet = isSelected ? 'radio-selected' : 'radio-unselected';

        return (
          <div
            className={`flex w-full items-center justify-between px-4 ${isDisabled ? 'cursor-not-allowed' : 'hover:cursor-pointer'}`}>
            <div className="flex w-full items-center justify-start space-x-3.5">
              <Icon className="h-4 w-4" name={bullet} />
              <Text slot="description" variant="p4">
                {label}
              </Text>
            </div>
            {icon && <Icon name={icon} />}
          </div>
        );
      }}
    </AriaRadioItem>
  );
}

RadioGroup.Item = RadioItem;
