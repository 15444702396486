import type { ActivityData } from './activity-tab-dto';
import { AmenitiesUsage } from './data-points/amenities-usage';
import { AppActivity } from './data-points/app-activity';
import { CommunityActivity } from './data-points/community-activity';
import { EventsParticipation } from './data-points/events-participation';
import { MoveInChecklist } from './data-points/move-in-checklist';
import { ResidentSatisfaction } from './data-points/resident-satisfaction';
import { ServiceRequests } from './data-points/service-requests';
import { UpcomingLease } from './data-points/upcoming-lease';

export function ActivityTab({
  events,
  groups,
  moveIn,
  appActivity,
  amenityReservations,
  serviceRequests,
  amenityTotalCharges,
  leaseContract,
}: ActivityData) {
  return (
    <div className="mt-6 flex w-full flex-col gap-4 px-4">
      {leaseContract && <UpcomingLease leaseContract={leaseContract} />}
      <ServiceRequests serviceRequests={serviceRequests} />
      <AmenitiesUsage
        amenityReservations={amenityReservations}
        amenityTotalCharges={amenityTotalCharges}
      />
      <EventsParticipation events={events} />
      <AppActivity appActivity={appActivity} />
      <CommunityActivity groups={groups} />
      <MoveInChecklist taskLists={moveIn.taskLists} />
      <ResidentSatisfaction />
    </div>
  );
}
