import { Text } from '@venncity/block';

export function NoSearchResults(value: string) {
  return (
    <div className="text-grey-900 flex flex-col gap-6 p-6 text-center">
      <Text>
        No results found for <b>&quot;{value}&quot;</b>
      </Text>
      <Text>Try adjusting your search to find what you&apos;re looking for</Text>
    </div>
  );
}
