import { Text } from '@venncity/block';

export function EmptyState({ stateType }: { stateType: string }) {
  return (
    <div className="text-grey-900 flex flex-col items-center p-6 text-center">
      <Text className="w-[280px]" variant="p4" weight="medium">
        There are no {stateType} in your community yet.
      </Text>
    </div>
  );
}
