import { Text } from '@venncity/block';
import { useTranslation } from 'react-i18next';
import { GeneralTable } from '~/components/DS/Table/GeneralTable';
import { TagCell } from '~/components/DS/Table/Cells/TagCell';
import { TextCell } from '~/components/DS/Table/Cells/TextCell';
import { type AmenityReservationStatus } from '~/genql';
import { amenityStatusTagColor } from '../activity-tab-dto';
import type { AmenityReservationDTO } from '../activity-tab-dto';
import { startCase, toLower } from 'lodash-es';
import { Section } from '../data-point-section';

export const AmenitiesUsage = ({
  amenityReservations,
  amenityTotalCharges,
}: {
  amenityReservations: AmenityReservationDTO[];
  amenityTotalCharges: number;
}) => {
  const { t } = useTranslation('resident-profile');

  const columns = [
    {
      title: t('amenities-usage.amenity'),
      key: 'amenityName',
      dataIndex: 'amenityName',
      render: (amenityName: string) => {
        return <TextCell text={amenityName} />;
      },
    },
    {
      title: t('amenities-usage.for-date'),
      key: 'forDate',
      dataIndex: 'forDate',
      render: (forDate: string) => {
        return <TextCell text={forDate} />;
      },
    },
    {
      title: t('amenities-usage.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status: AmenityReservationStatus) => {
        return (
          <TagCell
            tags={[
              {
                text: startCase(toLower(status)),
                color: amenityStatusTagColor[status],
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <Section title={t('amenities-usage.title')} subtitle={t('amenities-usage.subtitle')}>
      <div className="p-4">
        {amenityReservations.length > 0 ? (
          <div>
            <GeneralTable
              className="mb-4"
              columns={columns}
              dataSource={amenityReservations}
              emptyData={
                <Text className="flex  h-24 items-center justify-center">
                  {t('empty-state.amenities-usage')}
                </Text>
              }
              pagination={false}
              rowKey="id"
              scroll={{ y: 'calc(100vh - 650px)' }}
              size="small"
            />
            <Text>{t('amenities-usage.totalCharges', { count: amenityTotalCharges })}</Text>
          </div>
        ) : (
          <Text className="flex h-24 items-center justify-center" variant="p5">
            {t('empty-state.amenities-usage')}
          </Text>
        )}
      </div>
    </Section>
  );
};
