import { Resize } from '@cloudinary/url-gen/actions';
import { Text } from '@venncity/block';
import { useTranslation } from 'react-i18next';
import { cloudinary } from '~/lib/cloudinary';
import { ActivityGroupDTO } from '../activity-tab-dto';
import { Section } from '../data-point-section';

export const CommunityActivity = ({ groups }: { groups: ActivityGroupDTO[] | undefined }) => {
  const { t } = useTranslation('resident-profile');

  return (
    <Section title={t('community-activity.title')}>
      <div className="p-4">
        {groups?.length ? (
          <>
            <Text>{t('community-activity.groups-joined', { count: groups?.length })}</Text>
            {groups?.map((group) => (
              <div className="mt-3 flex" key={group.id}>
                {group.image ? (
                  <img
                    alt={group.name || 'group'}
                    className="h-12 w-12 rounded-xl object-cover"
                    src={cloudinary.image(group.image).resize(Resize.scale().width(700)).toURL()}
                  />
                ) : (
                  <div className="bg-grey-300 h-12 min-h-12 w-12 rounded-xl object-cover"></div>
                )}

                <div className="my-0.5 ml-3 flex flex-col justify-between ">
                  <Text className="line-clamp-2" variant="p5">
                    {group.name}
                  </Text>
                  <Text className="text-grey-700" variant="p5">
                    {`${group.count} ${t('group.count')}`}
                  </Text>
                </div>
              </div>
            ))}
          </>
        ) : (
          <Text className="flex h-24 items-center justify-center" variant="p5">
            {t('empty-state.community-activity')}
          </Text>
        )}
      </div>
    </Section>
  );
};
