import { Icon, Popover, Pressable, Text, GridList, FieldError } from '@venncity/block';
import { listBoxTriggerIcon } from '@venncity/block/electrons/list-box';
import clsx from 'clsx';
import React from 'react';
import { DialogTrigger } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { useAppLoaderData } from '~/utils/common';
import { useSearchNavigate } from '~/utils/search-params';

export function CommunitySelector({
  className,
  communitySelected,
  errorMessage,
}: {
  className?: string;
  communitySelected: string[];
  errorMessage: string | undefined | string[];
}) {
  const appLoaderData = useAppLoaderData();

  const searchNavigate = useSearchNavigate();

  const [selectOpen, setSelectOpen] = React.useState(false);
  const [selectedCommunities, setSelectedCommunities] = React.useState<string[]>(
    communitySelected || [],
  );

  //if communityIds fails validation in more than one place show only the first error message
  const displayedErrorMessage = Array.isArray(errorMessage) ? errorMessage[0] : errorMessage;

  return (
    <div className={twMerge(className, 'space-y-1')}>
      <Text weight="bold">*Community</Text>
      <div
        className={clsx(
          'border-grey-500 w-full overflow-hidden rounded-lg border focus:outline-none',
          selectOpen && 'border-grey-600 bg-grey-100',
        )}>
        {selectedCommunities?.map((key) => (
          <input key={key} name="community-id" type="hidden" value={key.toString()} />
        ))}
        <DialogTrigger onOpenChange={(isOpen) => setSelectOpen(isOpen)}>
          <CommunityTrigger communitiesSelected={selectedCommunities} />
          <Popover className="w-[460px]" offset={2}>
            <div className="space-y-2 overflow-y-auto">
              <div className="p-3 pt-0">
                <GridList
                  aria-label="community selector"
                  defaultSelectedKeys={communitySelected}
                  selectedKeys={selectedCommunities}
                  items={appLoaderData.user.managedCommunities}
                  onSelectionChange={(selection) => {
                    searchNavigate({ communityId: Array.from(selection).map(String) });
                    setSelectedCommunities(Array.from(selection).map(String));
                  }}
                  selectionMode="multiple">
                  {(item) => (
                    <GridList.Item id={item.id} key={item.id} textValue={item.name || ''}>
                      {item.displayName}
                    </GridList.Item>
                  )}
                </GridList>
              </div>
            </div>
          </Popover>
        </DialogTrigger>
      </div>
      {errorMessage && <FieldError aria-label="Community">{displayedErrorMessage}</FieldError>}
    </div>
  );
}
function CommunityTrigger({ communitiesSelected }: { communitiesSelected: string[] }) {
  return (
    <Pressable
      aria-label="community selector"
      className="rac-pressed:bg-grey-50 w-full rounded-lg bg-white p-4 focus:outline-none">
      <div className="flex w-full items-center justify-between">
        {communitiesSelected.length > 0 ? (
          <div className="flex flex-col gap-y-2 text-left">
            <Text>
              {communitiesSelected.length}{' '}
              {communitiesSelected.length === 1 ? 'Community' : 'Communities'}
            </Text>
          </div>
        ) : (
          <Text className="text-grey-600">Select communities...</Text>
        )}
        <span className="text-grey-900 flex-shrink-0 transition-transform group-data-[expanded=true]:rotate-180">
          <Icon className={listBoxTriggerIcon()} name="chevron-down-large" />
        </span>
      </div>
    </Pressable>
  );
}
