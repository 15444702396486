import { Text } from '@venncity/block';
import { Divider } from '@venncity/venn-ds';

export function Section({
  title,
  children,
  subtitle,
}: {
  title: string | React.ReactNode;
  subtitle?: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className="border-grey-300 rounded-2xl border bg-white">
      <div className="flex flex-col gap-2 px-6 py-4">
        {typeof title === 'string' ? (
          <Text variant="p4" weight="medium">
            {title}
          </Text>
        ) : (
          title
        )}
        {subtitle && <Text variant="p6">{subtitle}</Text>}
      </div>
      <Divider marginless />
      {children}
    </div>
  );
}
