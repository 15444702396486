import { RadioGroup, Text } from '@venncity/block';
import { useEffect } from 'react';
import type { BroadcastMediumOption } from '~/dto/notifications-form-dto';
import {
  BroadcastCombinationMedium,
  FormSearchParams,
  mapServerMediumToUI,
} from '~/dto/notifications-form-dto';
import { enumNotificationMedium } from '~/genql';
import { useSearchNavigate } from '~/utils/search-params';

const channel = FormSearchParams.Channel;
const allowedMediums: BroadcastMediumOption[] = [
  enumNotificationMedium.MOBILE_APP,
  enumNotificationMedium.EMAIL,
  BroadcastCombinationMedium.APP_AND_EMAIL,
];

interface MediumSelectorProps {
  selectedMedium: BroadcastMediumOption;
}

export function MediumSelector({ selectedMedium }: MediumSelectorProps) {
  const searchNavigate = useSearchNavigate();

  const setMedium = (medium: string) => {
    searchNavigate({ [FormSearchParams.Channel]: medium });
  };

  useEffect(() => {
    if (!selectedMedium) {
      setMedium(enumNotificationMedium.MOBILE_APP);
    } else if (selectedMedium && !allowedMediums.includes(selectedMedium)) {
      setMedium(enumNotificationMedium.MOBILE_APP);
    }
  }, [selectedMedium, searchNavigate]);

  const emailLabel = mapServerMediumToUI[enumNotificationMedium.EMAIL];

  return (
    <div className="space-y-1">
      <Text variant={'p4'} weight="bold">
        *Send by
      </Text>
      <RadioGroup
        className="flex flex-col space-y-2"
        label={channel}
        name={channel}
        onChange={setMedium}
        value={selectedMedium}>
        <RadioGroup.Item
          icon="bel-ringing"
          label={mapServerMediumToUI[enumNotificationMedium.MOBILE_APP]}
          stringValue={enumNotificationMedium.MOBILE_APP}
        />
        <RadioGroup.Item
          icon="mail"
          label={emailLabel}
          stringValue={enumNotificationMedium.EMAIL}
        />
        <RadioGroup.Item
          icon="announcment"
          label={mapServerMediumToUI[BroadcastCombinationMedium.APP_AND_EMAIL]}
          stringValue={BroadcastCombinationMedium.APP_AND_EMAIL}
        />
      </RadioGroup>
    </div>
  );
}
