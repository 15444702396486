import { cloudinary } from '~/lib/cloudinary';

export const useOrgLogo = (logoUrl: string) => {
  const fetchAndModifySVG = async (fillColor: string) => {
    const svgUrl = cloudinary.image(logoUrl).format('svg').toURL();
    if (!svgUrl) {
      return '';
    }
    try {
      const response = await fetch(svgUrl);
      const svgText = await response.text();

      const modifiedSVG = svgText.replace(/fill="#3E3A37"/g, `fill="${fillColor}"`);

      return modifiedSVG;
    } catch (error) {
      console.error('Error fetching or modifying SVG:', error);
      return '';
    }
  };

  return { fetchAndModifySVG };
};
