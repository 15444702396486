import { TagField } from '@venncity/block';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { useAsyncList } from 'react-stately';
import type { EventResourceDTO } from '~/dto/event-resource-dto';

interface EventsTagFieldProps {
  defaultValueFull?: EventResourceDTO[];
  placeholder?: string;
  name?: string;
  errorMessage?: string;
}

export default function EventsTagField({
  placeholder = 'Choose events',
  name = 'events',
  defaultValueFull,
  errorMessage,
}: EventsTagFieldProps) {
  const eventsList = useAsyncList<EventResourceDTO>({
    async load({ filterText }) {
      const { data } = await axios.get(`/resources/events`, {
        params: { q: filterText },
      });
      return { items: data || [] };
    },
  });

  return (
    <TagField
      defaultSelectedItems={defaultValueFull}
      errorMessage={errorMessage}
      getItemId={(item) => item.id}
      getItemLabel={(item) => item.name || ''}
      items={eventsList.items}
      name={name}
      onInputChange={debounce(eventsList.setFilterText, 400, {
        maxWait: 1000,
        leading: false,
        trailing: true,
      })}
      placeholder={placeholder}>
      {(item) => <span>{item.name}</span>}
    </TagField>
  );
}
