import { useSearchParams } from 'react-router';
import { DialogTrigger, Drawer } from '@venncity/block';
import { GeneralInfoPageFormTabs } from '~/dto/general-info-page-form-dto';
import { GlobalSearchParam, useSearchNavigate } from '~/utils/search-params';
import { EditGeneralInfoPageForm } from './edit-general-info-page-form';
import GeneralInfoPageForm from './general-info-page-form';
import { GeneralInfoPagePreview } from './general-info-page-preview';

export function GeneralInfoPageFormDialog() {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();

  const generalInfoPageId = searchParams.get(GlobalSearchParam.GeneralInfoPageId);
  const view = searchParams.get('view') || GeneralInfoPageFormTabs.Form;

  return (
    <DialogTrigger
      defaultOpen
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          searchNavigate(
            {
              dialogType: undefined,
              view: undefined,
              generalInfoPageId: undefined,
              isResidentHosted: undefined,
            },
            { preventScrollReset: true },
          );
        }
      }}>
      <Drawer className="flex flex-col overflow-hidden">
        <div className="flex min-h-0 flex-1 flex-col">
          {view === GeneralInfoPageFormTabs.Preview ? (
            <GeneralInfoPagePreview />
          ) : generalInfoPageId ? (
            <EditGeneralInfoPageForm />
          ) : (
            <GeneralInfoPageForm title="Create page" />
          )}
        </div>
      </Drawer>
    </DialogTrigger>
  );
}
