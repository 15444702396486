import { Text } from '@venncity/block';
import { useTranslation } from 'react-i18next';
import type { ServiceRequestsDTO } from '../activity-tab-dto';
import { capitalize, toLower } from 'lodash-es';
import { Section } from '../data-point-section';

export const ServiceRequests = ({ serviceRequests }: { serviceRequests: ServiceRequestsDTO }) => {
  const { t } = useTranslation('resident-profile');

  return (
    <Section title={t('service-requests.title')} subtitle={t('service-requests.subtitle')}>
      {serviceRequests.totalCount > 0 ? (
        <>
          <div className="border-grey-300 mx-6 flex flex-col gap-2 border-b py-4">
            <Text className="text-grey-600" variant="p5">
              {t('service-requests.overview.title')}
            </Text>
            <div>
              <Text variant="p5">
                {`${serviceRequests.totalCount} ${t('service-requests.total-request', { count: serviceRequests.totalCount })}`}
              </Text>
            </div>

            {serviceRequests.totalCount > 1 && (
              <Text variant="p5">
                {`Across ${serviceRequests.categoryList.length} ${t('service-requests.category', { count: serviceRequests.categoryList.length })}`}
              </Text>
            )}
          </div>
          <div className="border-grey-300 mx-6 flex flex-col gap-2 border-b py-4">
            <Text className="text-grey-600" variant="p5">
              {t('service-requests.resolution-time')}
            </Text>
            <Text variant="p5">{`${t('service-requests.average')}: ${serviceRequests.averageResolutionTime}`}</Text>
          </div>
          <div className="border-grey-300 mx-6 flex flex-col gap-2 border-b py-4">
            <Text className="text-grey-600" variant="p5">
              {t('service-requests.request-status')}
            </Text>
            <div className="flex flex-wrap gap-6">
              {serviceRequests.statusList.map((status) => (
                <Text variant="p5" key={status.status + status.count}>
                  {status.count} {formatText(status.status)}
                </Text>
              ))}
            </div>
          </div>
          <div className="border-grey-300 mx-6 flex flex-col gap-2 border-b py-4">
            <Text className="text-grey-600" variant="p5">
              {t('service-requests.open-requests')}
            </Text>
            <div className="flex flex-col gap-2">
              {serviceRequests.openServiceRequests?.length > 0
                ? serviceRequests.openServiceRequests?.map((request) => (
                    <Text variant="p5" key={request.id}>
                      {formatText(request.category)} - {formatText(request.status)} -{' '}
                      {request.openedAt}
                    </Text>
                  ))
                : t('open-requests.empty-state')}
            </div>
          </div>
        </>
      ) : (
        <div className="border-grey-300 mx-6 flex flex-col border-b py-4">
          <Text className="flex  h-24 items-center justify-center" variant="p5">
            {t('empty-state.service-requests')}
          </Text>
        </div>
      )}
    </Section>
  );
};

const formatText = (status?: string) =>
  status ? capitalize(toLower(status.replace(/_/g, ' '))) : '';
