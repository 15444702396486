import type { EventResourceDTO } from './event-resource-dto';
import type { PlaceResourceDTO } from './place-resource-dto';
import type { ProductResourceDTO } from './product-resource-dto';
import type { ServiceResourceDTO } from './service-resource-dto';

export interface GeneralInfoPageFormDTO {
  title: string;
  subtitle: string;
  description: string;
  publish: boolean;
  image: {
    id: string;
    url: string;
  };
  places?: PlaceResourceDTO[];
  events?: EventResourceDTO[];
  products?: ProductResourceDTO[];
  services?: ServiceResourceDTO[];
}

export const GeneralInfoPageFormTabs = {
  Form: 'form',
  Preview: 'preview',
} as const;

export const GeneralInfoPageFormAction = {
  Save: 'save',
  Preview: 'preview',
} as const;
