import { Text } from '@venncity/block';
import { useTranslation } from 'react-i18next';
import { Section } from '../data-point-section';
import { Tag, Tooltip } from '@venncity/venn-ds';
import { riskStatusLabelMap, TagColorMap } from '~/routes/_app+/renewals+/helpers/utils';
import { RiskCategory } from '~/genql';
import { LeaseContractDTO } from '../activity-tab-dto';

export const UpcomingLease = ({ leaseContract }: { leaseContract: LeaseContractDTO }) => {
  const { t } = useTranslation('resident-profile');
  const { renewalScore, endDate } = leaseContract ?? {};
  const riskCategory = renewalScore?.riskCategory ?? null;
  const { responseDate, response, comment, surveyStatus, sentDate } =
    leaseContract.renewalSurvey ?? {};
  const formatterResponseDate = responseDate
    ? t('service-requests.responded', { date: responseDate })
    : t('service-requests.awaiting-response');
  const surveyTriggered = leaseContract.renewalSurvey;
  const renewalPlansContent = surveyTriggered
    ? t('service-requests.survey-sent', { sentDate, formatterResponseDate })
    : t('resident-upcoming-lease.not-triggered');
  return (
    <Section
      title={
        <div className="flex gap-2">
          <Text weight="medium">{t('resident-upcoming-lease.title')}</Text>
          {riskCategory && <RiskTag riskCategory={riskCategory} />}
        </div>
      }>
      <div className="flex flex-col gap-3 p-4">
        <InnerSection
          title={t('resident-upcoming-lease.lease-end-date-title')}
          content={t('resident-upcoming-lease.days-to-lease-end', {
            leaseEndDate: endDate,
            daysToLeaseEnd: leaseContract.daysToLeaseEnd,
          })}
        />
        <InnerSection
          title={t('resident-upcoming-lease.renewal-plans-title')}
          content={renewalPlansContent}
        />
        {responseDate && surveyStatus && (
          <InnerSection
            title={t('resident-upcoming-lease.survey-status-title')}
            content={surveyStatus}
          />
        )}
        {responseDate && comment && response && <InnerSection title={response} content={comment} />}
      </div>
    </Section>
  );
};

const RiskTag = ({ riskCategory }: { riskCategory: RiskCategory }) => {
  const { t } = useTranslation('resident-profile');
  return (
    <Tooltip title={t('resident-upcoming-lease.tag-tooltip')}>
      <Tag color={TagColorMap[riskCategory]} key="remaining">
        {riskStatusLabelMap[riskCategory]}
      </Tag>
    </Tooltip>
  );
};

const InnerSection = ({ title, content }: { title: string; content: string }) => {
  return (
    <div className="flex flex-col">
      <Text className="text-p5 text-grey-600">{title}</Text>
      <Text className="text-p5">{content}</Text>
    </div>
  );
};
