import { TagField } from '@venncity/block';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { useAsyncList } from 'react-stately';
import type { PlaceResourceDTO } from '~/dto/place-resource-dto';

interface PlacesTagFieldProps {
  defaultValueFull?: PlaceResourceDTO[];
  placeholder?: string;
  name?: string;
  errorMessage?: string;
}

export default function PlacesTagField({
  placeholder = 'Choose places',
  name = 'places',
  defaultValueFull,
  errorMessage,
}: PlacesTagFieldProps) {
  const placesList = useAsyncList<PlaceResourceDTO>({
    async load({ filterText }) {
      const { data } = await axios.get(`/resources/places`, {
        params: { q: filterText },
      });
      return { items: data || [] };
    },
  });

  return (
    <TagField
      defaultSelectedItems={defaultValueFull}
      errorMessage={errorMessage}
      getItemId={(item) => item.id}
      getItemLabel={(item) => item.name || ''}
      items={placesList.items}
      name={name}
      onInputChange={debounce(placesList.setFilterText, 400, {
        maxWait: 1000,
        leading: false,
        trailing: true,
      })}
      placeholder={placeholder}>
      {(item) => <span>{item.name}</span>}
    </TagField>
  );
}
