import { TagColors } from '@venncity/venn-ds';
import type {
  AmenityReservationStatus,
  AppOnboardingStatus,
  ContractStatus,
  RiskCategory,
} from '~/genql';
import { enumAmenityReservationStatus, enumAppOnboardingStatus } from '~/genql';

export type ActivityData = {
  events: ActivityEventDTO[];
  groups?: ActivityGroupDTO[];
  moveIn: {
    taskLists: TaskListsDTO[];
  };
  appActivity: AppActivityDTO;
  amenityReservations: AmenityReservationDTO[];
  amenityTotalCharges: number;
  serviceRequests: ServiceRequestsDTO;
  leaseContract: LeaseContractDTO | null;
};

export type LeaseContractDTO = {
  id: string;
  building: string;
  unit: string;
  address?: string;
  startDate: string;
  endDate: string;
  moveInDate: string;
  moveOutDate: string;
  daysToLeaseEnd: number;
  status: ContractStatus | null;
  pmsStatus: string | null;
  externalId: string | null;
  rent: string;
  renewalScore: {
    medianResolutionTime: number | null;
    riskCategory?: RiskCategory;
  } | null;
  renewalSurvey: Partial<RenewalSurvey> | null;
};

export type ServiceRequestsDTO = {
  openServiceRequests: OpenServiceRequestsDTO[];
  totalCount: number;
  categoryList: string[];
  statusList: ServiceRequestsStatusesDTO[];
  averageResolutionTime: string;
};

export type ActivityEventDTO = {
  id: string;
  name: string;
  date: string;
  image: string;
};

export type ActivityGroupDTO = {
  id: string;
  name: string;
  image?: string;
  count: number;
};

export type TaskListsDTO = {
  unitName: string;
  status: string;
  id: string;
  tasks: {
    status: string;
    id: string;
    name: string;
  }[];
};

export type AppActivityDTO = {
  lastLogin: string;
  appOnboardingStatus: AppOnboardingStatus;
};

export type AmenityReservationDTO = {
  id: string;
  amenityName: string;
  forDate: string;
  status: AmenityReservationStatus | string;
  amountPaid: string;
};

type OpenServiceRequestsDTO = {
  id: string;
  category: string;
  status: string;
  openedAt: string;
};

type ServiceRequestsStatusesDTO = {
  status: string;
  count: number;
};

export const onboardingStatusTagColor: Record<AppOnboardingStatus, TagColors> = {
  [enumAppOnboardingStatus.IN_COMPLETE]: TagColors.ERROR,
  [enumAppOnboardingStatus.COMPLETE]: TagColors.SUCCESS,
};

export const amenityStatusTagColor: Record<AmenityReservationStatus, TagColors> = {
  [enumAmenityReservationStatus.APPROVED]: TagColors.SUCCESS,
  [enumAmenityReservationStatus.DENIED]: TagColors.ERROR,
  [enumAmenityReservationStatus.CANCELLED]: TagColors.ERROR,
  [enumAmenityReservationStatus.PENDING_APPROVAL]: TagColors.WARNING,
  [enumAmenityReservationStatus.PENDING_PAYMENT]: TagColors.WARNING,
};

export type FormattedLeaseContract = {
  id: string;
  building: string;
  unit: string;
  address?: string;
  startDate: string;
  endDate: string;
  moveInDate: string;
  moveOutDate: string;
  daysToLeaseEnd: number;
  status: ContractStatus | null;
  pmsStatus: string;
  externalId: string;
  rent: string;
  renewalScore?: {
    medianResolutionTime: number;
    riskCategory?: RiskCategory;
  };
  renewalSurvey: RenewalSurvey | null;
};

export type RenewalSurvey = {
  leaseEndDate: string;
  sentDate: string;
  responseDate: string | null;
  surveyStatus: string;
  response: string | false | null;
  comment?: string | null;
  riskCategory?: RiskCategory;
};
