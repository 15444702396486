import { useSearchParams } from 'react-router';
import { DialogTrigger, Drawer } from '@venncity/block';
import { useTranslation } from 'react-i18next';
import { DiscussionFormTabs } from '~/dto/discussions-form-dto';
import { useSearchNavigate } from '~/utils/search-params';
import DiscussionsForm from './discussion-form';
import { DiscussionPreview } from './discussion-preview';
import DiscussionEdit from './edit-discussion-form';

export function DiscussionFormDialog() {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();
  const { t } = useTranslation(['discussion-create']);
  const discussionId = searchParams.get('discussion-id');
  const tab = searchParams.get('tab') || DiscussionFormTabs.Form;

  return (
    <DialogTrigger
      defaultOpen
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          searchNavigate(
            {
              dialogType: undefined,
              discussionId: undefined,
              view: undefined,
            },
            { preventScrollReset: true },
          );
        }
      }}>
      <Drawer className="flex flex-col overflow-hidden">
        <div className="flex min-h-0 flex-1 flex-col">
          {tab === DiscussionFormTabs.Preview ? (
            <DiscussionPreview />
          ) : discussionId ? (
            <DiscussionEdit />
          ) : (
            <DiscussionsForm title={t('title')} />
          )}
        </div>
      </Drawer>
    </DialogTrigger>
  );
}

export const handle = {
  i18n: ['discussion-create'],
};
