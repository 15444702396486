import { useFetcher } from 'react-router';
import { FieldError, Pressable } from '@venncity/block';
import { Avatar } from '@venncity/venn-ds';
import { isNil } from 'lodash-es';
import React from 'react';
import { Input } from 'react-aria-components';
import { cloudinary } from '~/lib/cloudinary';
import type { ActionResponseBody } from '~/utils/http.server';

const MAX_FILE_SIZE = 5_000_000; // bytes
const MAX_SIZE_ERROR_MESSAGE =
  'The file you tried to upload is too large. Please select a file up to 5MB.';

enum ImageTypes {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  SVG = 'image/svg+xml',
  GIF = 'image/gif',
}

const accept = [ImageTypes.JPEG, ImageTypes.PNG, ImageTypes.GIF, ImageTypes.SVG];

export function ProfileImageUpload({ defaultValue }: { defaultValue?: string }) {
  const fetcher = useFetcher<ActionResponseBody & { image?: string }>();
  const fileButtonRef = React.useRef<HTMLInputElement>(null);
  const [imageResourceId, setImageResourceId] = React.useState(defaultValue);
  const [sizeLimitErrorMessage, setSizeLimitErrorMessage] = React.useState('');

  React.useEffect(() => {
    if (fetcher.data?.image) {
      setImageResourceId(fetcher.data?.image);
    }
  }, [fetcher.data]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    setSizeLimitErrorMessage('');

    const file = event.target?.files?.[0] as File;

    if (file.size > MAX_FILE_SIZE) {
      return setSizeLimitErrorMessage(MAX_SIZE_ERROR_MESSAGE);
    }

    const formData = new FormData();

    formData.append('img', file);

    fetcher.submit(formData, {
      method: 'post',
      encType: 'multipart/form-data',
      action: '/resources/cloudinary/image-upload',
    });
  }

  return (
    <div className="flex flex-col items-center">
      <Pressable
        className="focus:outline-none"
        isDisabled={fetcher.formMethod === 'POST' && fetcher.state === 'submitting'}
        onPress={() => {
          fileButtonRef?.current?.click();
        }}>
        <Avatar src={imageResourceId ? cloudinary.image(imageResourceId).toURL() : ''} superSize />
      </Pressable>
      <div className="flex flex-col items-center">
        <Input
          accept={accept.join(',')}
          className="sr-only"
          key={imageResourceId}
          onChange={handleChange}
          ref={fileButtonRef}
          type="file"
        />
        <input
          name="profile-image"
          type="hidden"
          value={isNil(imageResourceId) ? '' : imageResourceId}
        />
        {sizeLimitErrorMessage && <FieldError>{sizeLimitErrorMessage}</FieldError>}
        <Pressable
          className="text-grey-900 hover:text-grey-500 focus: mt-5 text-center font-bold outline-none active:outline-none"
          onPress={() => {
            fileButtonRef?.current?.click();
          }}>
          Edit
        </Pressable>
      </div>
    </div>
  );
}
