import { useTranslation } from 'react-i18next';
import { EventForm } from './event-form';

export function CreateEventForm() {
  const { t } = useTranslation(['event-create']);

  return <EventForm title={t('title')} />;
}

export const handle = {
  i18n: ['event-create'],
};
