import { VariantProps, cva } from 'class-variance-authority';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import Icon, { IconName } from '../icon/icon';
import { Loader } from '../loader/loader';
import { Pressable } from '../pressable/pressable';
import { Text } from '../text/text';

const alert = cva('flex flex-col items-start  p-4', {
  variants: {
    variant: {
      default: ['bg-stone-100', 'text-grey-900'],
      positive: ['bg-[#F4F8F5]', 'text-[#003D1F]'],
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export function Alert({
  icon,
  title,
  description,
  cta,
  variant,
  onClose,
  className,
  isLoading,
  children,
}: {
  icon?: IconName;
  title?: string;
  description: string;
  cta?: { name: string; onPress?: () => void; variant: 'button' | 'link' };
  isLoading?: boolean;
  onClose?: () => void;
  className?: string;
  children?: React.ReactNode;
} & VariantProps<typeof alert>) {
  return (
    <article className={twMerge(alert({ variant }), className)}>
      <div className="flex w-full ">
        <div className="flex flex-1 gap-x-3">
          {icon && <Icon className="mt-0.5 h-5 w-5" name={icon} />}
          <div className="flex flex-col gap-y-1">
            <Text variant="p4" weight="bold">
              {title}
            </Text>
            <div>
              <Text variant="p5">{description}</Text>
              {cta?.variant === 'link' && (
                <Pressable className={'outline-none'} onPress={cta.onPress} type="submit">
                  &nbsp;
                  <Text className="underline underline-offset-2 " variant="p5" weight={'bold'}>
                    {cta.name}
                  </Text>
                </Pressable>
              )}
            </div>
            {children}
            {cta?.variant === 'button' && (
              <div>
                <Pressable
                  className={twMerge(
                    'text-grey-900 active:bg-grey-200  mt-2 flex justify-center rounded-lg py-1.5 outline-none',
                    !isLoading && 'border-grey-900 justify-start border px-[18px]',
                  )}
                  isDisabled={isLoading}
                  onPress={cta.onPress}
                  type="submit">
                  {isLoading ? (
                    <Loader variant={'muted'} />
                  ) : (
                    <Text variant={'p5'} weight={'medium'}>
                      {cta.name}
                    </Text>
                  )}
                </Pressable>
              </div>
            )}
          </div>
        </div>
        {onClose && (
          <Pressable className="flex items-start outline-none" onPress={onClose}>
            <Icon className="text-grey-500 h-4 w-4 " name="close" />
          </Pressable>
        )}
      </div>
    </article>
  );
}
