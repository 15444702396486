import { Accordion, Text } from '@venncity/block';
import { Divider } from '@venncity/venn-ds';
import { AnimatePresence, motion } from 'motion/react';
import { useTranslation } from 'react-i18next';
import type { ContractStatus } from '~/genql';

interface LeaseContractDTO {
  id: string;
  building: string;
  unit: string;
  address?: string;
  startDate: string;
  endDate: string;
  moveInDate: string;
  moveOutDate: string;
  daysToLeaseEnd: number;
  status: ContractStatus | null;
  pmsStatus: string | null;
  externalId: string | null;
  rent: string;
}

interface LeaseContractsDTO {
  active: LeaseContractDTO[];
  future: LeaseContractDTO[];
  applicant: LeaseContractDTO[];
  inactive: LeaseContractDTO[];
}

export interface LeaseDataTab {
  leaseContracts: LeaseContractsDTO;
}

export function LeasesTab({ leaseContracts }: LeaseDataTab) {
  const { t } = useTranslation('resident-profile');

  return (
    <div className="mt-6 flex min-h-0 flex-col gap-8 truncate px-4">
      {leaseContracts.active.length > 0 && (
        <LeaseContractDetails
          id="ACTIVE"
          label={t('label.active')}
          leaseContracts={leaseContracts.active}
        />
      )}
      {leaseContracts.future.length > 0 && (
        <LeaseContractDetails
          id="FUTURE"
          label={t('label.future')}
          leaseContracts={leaseContracts.future}
        />
      )}
      {leaseContracts.applicant.length > 0 && (
        <LeaseContractDetails
          id="APPLICANT"
          label={t('label.applicant')}
          leaseContracts={leaseContracts.applicant}
        />
      )}
      {leaseContracts.inactive.length > 0 && (
        <LeaseContractDetails
          id="INACTIVE"
          label={t('label.inactive')}
          leaseContracts={leaseContracts.inactive}
        />
      )}
    </div>
  );
}

function LeaseContractDetails({
  label,
  leaseContracts,
  id,
}: {
  label: string;
  leaseContracts: LeaseContractDTO[];
  id?: string;
}) {
  const { t } = useTranslation('resident-profile');

  return (
    <div className="flex flex-col">
      <Text className="text-grey-900 mb-2 flex items-center " variant="p4" weight="medium">
        {label}
      </Text>
      {leaseContracts.map((leaseContract) => {
        return (
          <Accordion defaultExpandedKeys={['ACTIVE']} key={leaseContract.id}>
            <Accordion.Item className="flex flex-col gap-2 pt-2" id={id} key={leaseContract.id}>
              {({ isExpanded }) => {
                return (
                  <>
                    <AnimatePresence>
                      <Accordion.Header className="pb-2 pt-1" isExpanded={isExpanded}>
                        <Text className="text-grey-800 truncate" variant="p4" weight="normal">
                          {`${leaseContract.building} ${
                            leaseContract.unit ? '|' : ''
                          } ${leaseContract.unit}`}
                        </Text>
                      </Accordion.Header>
                      {!isExpanded && <Divider marginless />}
                      <Accordion.Panel>
                        <motion.div
                          animate={{ height: 'auto', opacity: 1 }}
                          className="overflow-hidden"
                          exit={{ opacity: 0, height: 0 }}
                          initial={{ height: 0, marginTop: 0, opacity: 1 }}
                          transition={{ bounce: 0.5, ease: 'circInOut' }}>
                          <div className="flex flex-col gap-4">
                            <Section>
                              <SectionItem label="Address" value={leaseContract.address} />

                              <SectionItem label="Rent" value={leaseContract.rent} />
                            </Section>

                            <Section>
                              <SectionItem
                                label={t('label.pms-status')}
                                value={leaseContract.pmsStatus || ''}
                              />

                              <SectionItem
                                label={t('label.external-id')}
                                value={leaseContract.externalId || ''}
                              />
                            </Section>
                            <Section>
                              <SectionItem
                                label={t('label.move-in-date')}
                                value={handleDate(leaseContract.moveInDate)}
                              />
                              <SectionItem
                                label={t('label.move-out-date')}
                                value={handleDate(leaseContract.moveOutDate)}
                              />
                            </Section>
                            <Section>
                              <SectionItem
                                label={t('label.lease-start-date')}
                                value={handleDate(leaseContract.startDate)}
                              />
                              <SectionItem
                                label={t('label.lease-end-date')}
                                value={handleDate(leaseContract.endDate)}
                              />
                            </Section>
                            {isExpanded && <Divider marginless />}
                          </div>
                        </motion.div>
                      </Accordion.Panel>
                    </AnimatePresence>
                  </>
                );
              }}
            </Accordion.Item>
          </Accordion>
        );
      })}
    </div>
  );
}
function Section({ children }: { children: React.ReactNode }) {
  return <div className="flex flex-row gap-x-6">{children}</div>;
}

function SectionItem({ label, value }: { label?: string; value?: string }) {
  return (
    <div className="flex flex-1 flex-col gap-2 overflow-hidden">
      <Text className="text-grey-600 overflow-hidden text-ellipsis" variant="p5">
        {label}
      </Text>
      <Text className="text-grey-800 overflow-hidden text-ellipsis" variant="p4">
        {value || 'N/A'}
      </Text>
    </div>
  );
}

function handleDate(date: string): string | undefined {
  if (date === 'Jan 1, 1970') {
    return undefined;
  }
  return date;
}
