import { useSearchParams } from 'react-router';
import React from 'react';
import type { loader } from '~/routes/resources+/notifications+/move-in';
import { useResourceData } from '~/utils/http';
import NotificationFormDialog from './notification-form-dialog';

export function CreateMoveInNotificationDialog() {
  const [searchParams] = useSearchParams();

  const { data } = useResourceData<typeof loader>(
    `/resources/notifications/move-in?${searchParams.toString()}`,
  );

  const residents = data?.user ? [data.user] : data?.users || [];

  const userIds = React.useMemo(() => {
    let ids: string[] = [];

    if (data?.user) {
      ids = [data.user.id];
    } else if (data?.users) {
      ids = data.users.map((user) => user.id);
    }

    return ids;
  }, [data?.user, data?.users]);

  return (
    <NotificationFormDialog
      initialValues={{
        residents,
        audience: 'SPECIFIC_MEMBERS',
        audienceParams: {
          userIds,
          __typename: 'SpecificUsersAudienceParams',
        },
        title: data?.title,
        message: data?.message,
      }}
      title="Create move-in message"
    />
  );
}
