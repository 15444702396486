import { useFetcher, useSearchParams } from 'react-router';
import { TextField, RichText, Dialog } from '@venncity/block';
import { Button, Switch } from '@venncity/venn-ds';
import { isEmpty } from 'lodash-es';
import { useEffect, useRef } from 'react';
import type { ValueOf } from 'type-fest';
import EventsTagField from '~/components/events-tag-field';
import { ImageUpload } from '~/components/image-upload';
import PlacesTagField from '~/components/places-tag-field';
import ProductsTagField from '~/components/products-tag-field';
import ServicesTagField from '~/components/sevices-tag-field';
import type { GeneralInfoPageFormDTO } from '~/dto/general-info-page-form-dto';
import {
  GeneralInfoPageFormAction,
  GeneralInfoPageFormTabs,
} from '~/dto/general-info-page-form-dto';
import useScrollToError from '~/utils/hooks/useScrollToError';
import type { ActionResponseBody } from '~/utils/http.server';
import { GlobalSearchParam, useSearchNavigate } from '~/utils/search-params';
import { CommunityCombobox } from '../community-combobox';

interface GeneralInfoPageFormProps {
  initialValues?: GeneralInfoPageFormDTO;
  title: string;
}

export default function GeneralInfoPageForm({ initialValues, title }: GeneralInfoPageFormProps) {
  const fetcher = useFetcher<ActionResponseBody<{ id: string }>>();
  const dialogRef = useRef<HTMLElement>(null);

  const searchNavigate = useSearchNavigate();
  const [searchParams] = useSearchParams();

  const generalInfoPageId = searchParams.get(GlobalSearchParam.GeneralInfoPageId);

  const buttonLabel = generalInfoPageId ? 'Update' : 'Create';

  const anyFetchersArePending =
    fetcher.formMethod === 'POST' && ['loading', 'submitting'].includes(fetcher.state);

  const isSaving =
    anyFetchersArePending && fetcher.formData?.get('action') === GeneralInfoPageFormAction.Save;
  const isPreviewing =
    anyFetchersArePending && fetcher.formData?.get('action') === GeneralInfoPageFormAction.Preview;

  useEffect(() => {
    if (fetcher.state === 'loading' && !isEmpty(fetcher.data?.data)) {
      const action = fetcher.formData?.get('action') as ValueOf<typeof GeneralInfoPageFormAction>;

      if (action === GeneralInfoPageFormAction.Preview) {
        searchNavigate(
          { view: GeneralInfoPageFormTabs.Preview, generalInfoPageId: fetcher.data?.data?.id },
          { preventScrollReset: true },
        );
      } else {
        searchNavigate(
          {
            view: undefined,
            generalInfoPageId: undefined,
            dialogType: undefined,
            isResidentHosted: undefined,
          },
          { preventScrollReset: true },
        );
      }
    }
  }, [fetcher.data, fetcher.formData, fetcher.state, searchNavigate]);

  useScrollToError(dialogRef, fetcher.data?.errors || {}, isSaving);

  return (
    <Dialog ref={dialogRef}>
      <Dialog.Header title={title} />
      <fetcher.Form
        action={
          generalInfoPageId
            ? `/resources/general-info-pages/${generalInfoPageId}/edit`
            : '/resources/general-info-pages/new'
        }
        method="POST">
        <Dialog.Body>
          <TextField
            defaultValue={initialValues?.title}
            description="Write a title for your new general info page"
            errorMessage={fetcher.data?.errors?.title}
            id="title"
            label="Title"
            name="title"
          />
          <TextField
            defaultValue={initialValues?.subtitle}
            description="Write a subtitle for your new general info page"
            errorMessage={fetcher.data?.errors?.subtitle}
            id="subtitle"
            label="Subtitle"
            name="subtitle"
          />
          <CommunityCombobox className={generalInfoPageId ? 'hidden' : ''} />
          <RichText
            defaultValue={initialValues?.description}
            errorMessage={fetcher.data?.errors?.description}
            label="Page content"
            name="description"
          />
          <Switch
            aria-label="Publish"
            className="my-1"
            defaultChecked={initialValues?.publish}
            id="publish"
            label="Publish"
          />
          <ImageUpload
            defaultValue={initialValues?.image?.id}
            description="Pick an image for your general info page"
            errorMessage={
              !initialValues?.image?.id
                ? fetcher.data?.errors?.imageId || fetcher.data?.errors?.imageFormat
                : undefined
            }
            label="Image"
            name="image"
          />
          <PlacesTagField defaultValueFull={initialValues?.places} />
          <EventsTagField defaultValueFull={initialValues?.events} />
          <ServicesTagField defaultValueFull={initialValues?.services} />
          <ProductsTagField defaultValueFull={initialValues?.services} />
        </Dialog.Body>
        <Dialog.Separator />
        <Dialog.Footer className="justify-between">
          <Button
            disabled={anyFetchersArePending}
            formNoValidate={true}
            htmlType="submit"
            loading={isPreviewing}
            name="action"
            value={GeneralInfoPageFormAction.Preview}
            variant="outlined">
            Preview
          </Button>
          <Button
            disabled={anyFetchersArePending}
            formNoValidate={true}
            htmlType="submit"
            loading={isSaving}
            name="action"
            type="primary"
            value={GeneralInfoPageFormAction.Save}>
            {buttonLabel}
          </Button>
        </Dialog.Footer>
      </fetcher.Form>
    </Dialog>
  );
}
