import { Text } from '@venncity/block';
import { Tag } from '@venncity/venn-ds';
import { AppActivityDTO, onboardingStatusTagColor } from '../activity-tab-dto';
import { startCase, toLower } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Section } from '../data-point-section';

export const AppActivity = ({ appActivity }: { appActivity: AppActivityDTO }) => {
  const { t } = useTranslation('resident-profile');

  return (
    <Section title={t('app-activity.title')}>
      <div className="flex flex-col gap-2 px-6 py-4">
        <div className="flex items-center">
          <Text className="mr-2" variant="p5" weight="bold">
            {t('app-activity.last-login')}
          </Text>
          <Text variant="p5">{appActivity.lastLogin}</Text>
        </div>
        <div className="flex items-center">
          <Text className="mr-2" variant="p5" weight="bold">
            {t('app-activity.onboarding-status')}
          </Text>
          <Tag color={onboardingStatusTagColor[appActivity.appOnboardingStatus]}>
            <Text variant="p5">
              {startCase(toLower(appActivity.appOnboardingStatus.replace('_', '')))}
            </Text>
          </Tag>
        </div>
      </div>
    </Section>
  );
};
