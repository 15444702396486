import { useFetcher, useSearchParams } from 'react-router';
import { Drawer, Dialog, Heading, VisuallyHidden } from '@venncity/block';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { Radio, RadioGroup } from 'react-aria-components';
import { cloudinary } from '~/lib/cloudinary';
import type { loader } from '~/routes/resources+/settings+/background-image';
import { useResourceData } from '~/utils/http';
import { useSearchNavigate } from '~/utils/search-params';

export function ChangeBackGroundDialog() {
  const searchNavigate = useSearchNavigate();
  const { data } = useResourceData<typeof loader>(`/resources/settings/background-image`);
  const fetcher = useFetcher();
  const [searchParams] = useSearchParams();

  const backgroundImageBasePath = 'experience-hub/common/background-images/';

  const backgroundImages = [
    {
      // This is the default one
      publicId: backgroundImageBasePath + 'Sky_pink_wspccs',
      description: 'default background of sky pink',
    },
    {
      publicId: backgroundImageBasePath + 'skyline_w6grbf',
      description: 'skyline background',
    },
    {
      publicId: backgroundImageBasePath + 'gradient2_tofdq6',
      description: 'blue yellow orange gradient',
    },
    {
      publicId: backgroundImageBasePath + 'light-sky_tgj9lb',
      description: 'light sky',
    },
    {
      publicId: backgroundImageBasePath + 'grey-tan-gradient_mrayqw',
      description: 'grey tan gradient',
    },

    {
      publicId: backgroundImageBasePath + 'dark_blue_jw1bwk',
      description: 'dark blue',
    },
    {
      publicId: backgroundImageBasePath + 'Guggenheim_veq90w',
      description: 'gugenheim',
    },
    {
      publicId: backgroundImageBasePath + 'Ice_Cream_vhtenb',
      description: 'ice cream',
    },
    {
      publicId: backgroundImageBasePath + 'the_cat_cwjdox',
      description: 'the cat',
    },
    {
      publicId: backgroundImageBasePath + 'Green_splash_wqkacr',
      description: 'green splash',
    },
    {
      publicId: backgroundImageBasePath + 'Light_wflehq',
      description: 'light side of building',
    },
    {
      publicId: backgroundImageBasePath + 'Sunset_gr3tlu',
      description: 'sunset skyline',
    },
    {
      publicId: backgroundImageBasePath + 'Spots_light_ki3ntk',
      description: 'light blurred spots',
    },
    {
      publicId: backgroundImageBasePath + 'NYC_mef8ep',
      description: 'NYC skyline',
    },
  ];

  const selectedBackgroundImage = searchParams.get('background') || data?.backgroundImageId;

  React.useEffect(() => {
    if (fetcher.state === 'idle' && !isEmpty(fetcher.data)) {
      searchNavigate({
        dialogType: undefined,
      });
    }
  }, [fetcher.data, fetcher.state, searchNavigate]);

  return (
    <Drawer
      className="md:w-auto"
      defaultOpen
      isDismissable
      onOpenChange={() => {
        searchNavigate({
          dialogType: undefined,
        });
      }}>
      <Dialog>
        <Dialog.Header />
        <Dialog.Body>
          <div className="flex flex-col items-center">
            <Heading slot="title" variant="h3">
              Change your background
            </Heading>
          </div>
          <RadioGroup
            aria-label="Background image selection"
            className="mt-8 grid h-full grid-cols-1 justify-items-center gap-5 md:grid-cols-2"
            defaultValue={selectedBackgroundImage}
            key={selectedBackgroundImage}
            name="background"
            onChange={(value) => {
              const formData = new FormData();

              formData.append('background-image', value);

              fetcher.submit(formData, {
                action: '/resources/settings/background-image',
                method: 'POST',
              });
            }}>
            {backgroundImages.map((image) => (
              <Radio
                className="rac-selected:ring-2 rac-selected:ring-grey-900 relative flex h-36 w-full flex-col items-center justify-end overflow-hidden rounded-xl bg-black/20 bg-cover bg-center text-white bg-blend-darken transition-all duration-300 hover:cursor-pointer hover:bg-black/10 md:w-64"
                key={image.description}
                style={{
                  backgroundImage: `url(${cloudinary
                    .image(image.publicId)
                    .quality('auto')
                    .format('webp')
                    .toURL()})`,
                }}
                value={image.publicId}>
                <VisuallyHidden>{image.description}</VisuallyHidden>
              </Radio>
            ))}
          </RadioGroup>
        </Dialog.Body>
      </Dialog>
    </Drawer>
  );
}
