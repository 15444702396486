import { useSearchParams } from 'react-router';
import { DialogTrigger, Drawer } from '@venncity/block';
import { FeaturedSlideFormTabs } from '~/dto/featured-slide-dto';
import { GlobalSearchParam, useSearchNavigate } from '~/utils/search-params';
import { CreateFeaturedSlideForm } from './create-featured-slide-form';
import { EditFeaturedSlideForm } from './edit-featured-slide-form';
import { FeaturedSlidePreview } from './featured-slide-preview';

export function FeaturedSlideFormDialog() {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();

  const featuredSlideId = searchParams.get('featured-slide-id');
  const communityId = searchParams.get(GlobalSearchParam.CommunityId);
  const view = searchParams.get('view') || FeaturedSlideFormTabs.Form;

  return (
    <DialogTrigger
      defaultOpen
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          searchNavigate(
            {
              dialogType: undefined,
              view: undefined,
              featuredSlideId: undefined,
              isResidentHosted: undefined,
            },
            { preventScrollReset: true },
          );
        }
      }}>
      <Drawer className="flex flex-col overflow-hidden">
        <div className="flex min-h-0 flex-1 flex-col">
          {view === FeaturedSlideFormTabs.Preview ? (
            <FeaturedSlidePreview communityId={communityId} />
          ) : featuredSlideId ? (
            <EditFeaturedSlideForm communityId={communityId} />
          ) : (
            <CreateFeaturedSlideForm />
          )}
        </div>
      </Drawer>
    </DialogTrigger>
  );
}
