import { Resize } from '@cloudinary/url-gen/actions';
import { Text } from '@venncity/block';
import { cloudinary } from '~/lib/cloudinary';
import { ActivityEventDTO } from '../activity-tab-dto';
import { useTranslation } from 'react-i18next';
import { Section } from '../data-point-section';

export const EventsParticipation = ({ events }: { events: ActivityEventDTO[] }) => {
  const { t } = useTranslation('resident-profile');

  return (
    <Section title={t('events.title')} subtitle={t('events.subtitle')}>
      <div className="p-4">
        {events?.length ? (
          events?.map((event) => (
            <div className="mt-3 flex" key={event.id}>
              <img
                alt={event.name}
                className="h-16 w-24 rounded-xl object-cover"
                src={
                  event?.image
                    ? cloudinary
                        .image(event.image)
                        .format('webp')
                        .resize(Resize.fill().width(100).height(70))
                        .toURL()
                    : ''
                }
              />
              <div className="ml-3 flex flex-col justify-between">
                <Text className="line-clamp-2" variant="p5">
                  {event.name}
                </Text>
                <Text className="text-grey-700" variant="p5">
                  {event.date}
                </Text>
              </div>
            </div>
          ))
        ) : (
          <Text className="flex h-24 items-center justify-center" variant="p5">
            {t('empty-state.events')}
          </Text>
        )}
      </div>
    </Section>
  );
};
