import { ShouldRevalidateFunctionArgs, Link, Outlet, useFetcher } from 'react-router';
import * as Sentry from '@sentry/react';
import { Heading } from '@venncity/block';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { sortBy } from 'lodash-es';
import { AnimatePresence, motion } from 'motion/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClientOnly } from 'remix-utils/client-only';
import Smartlook from 'smartlook-client';
import { ContentArea } from '~/components/content-area';
import { analytics, useAnalytics } from '~/lib/analytics';
import { cloudinary } from '~/lib/cloudinary';
import { getSession, requireCommunityIdParam } from '~/lib/session.server';
import { useOrgLogo } from '~/routes/_app+/_layout/helpers/hooks/useOrgLogo';
import { getFeatureFlags } from '~/services/flagsmith.server';
import getBuildingSettings from '~/services/get-building-settings';
import { getCurrentUser } from '~/services/get-current-user';
import getOrgLogo from '~/services/get-org-logo';
import { getHubMetadata } from '~/services/hub-metadata-service';
import { getMaintenanceDashboardSettings } from '~/services/is-maintenance-dashboard-enabled.server';
import { useIsomorphicLayoutEffect } from '~/utils/dom';
import { FeatureFlags } from '~/utils/feature-flags';
import type { GlobalDialogTypeUnion } from '~/utils/search-params';
import { GlobalDialogType, GlobalSearchParam, useUrl } from '~/utils/search-params';
import { sideMenuOpen, walkMeShown } from '~/utils/state';
import { getUserFullName, getUserInitials, getUserShortenedName, isValidUrl } from '~/utils/string';
import { DiscussionFormDialog } from './helpers/components/discussions/discussion-form-dialog';
import { EventFormDialog } from './helpers/components/events/event-form-dialog';
import { FeaturedSlideFormDialog } from './helpers/components/featured-slides/featured-slide-form-dialog';
import { GeneralInfoPageFormDialog } from './helpers/components/general-info-pages/general-info-page-form-dialog';
import { GroupFormDialog } from './helpers/components/groups/group-form-dialog';
import { CreateMoveInNotificationDialog } from './helpers/components/notifications/create-move-in-notification-dialog';
import { CreateNotificationDialog } from './helpers/components/notifications/create-notification-dialog';
import { CreateResidentNotificationDialog } from './helpers/components/notifications/create-resident-notification-dialog';
import { EditNotificationDialog } from './helpers/components/notifications/edit-notification-dialog';
import ResidentProfile from './helpers/components/residents/resident-profile';
import { ChangeBackGroundDialog } from './helpers/components/settings/change-background-dialog';
import IntroductoryVideoDialog from './helpers/components/settings/Introductory-video-dialog';
import { SettingsDialog } from './helpers/components/settings/settings-dialog';
import { SideMenu } from './helpers/components/side-menu/side-menu';
import {
  UserProfileDialog,
  UserProfileDialogTrigger,
} from './helpers/components/user-profile-dialog';
import { SpotlightSearch } from '~/components/spotlight-search';
import type { Route } from './+types';

const LG_BREAKPOINT = 1024;

export async function loader({ request }: Route.LoaderArgs) {
  const session = await getSession(request);
  const userId = session.get('user-id');
  const userAvatar = session.get('user-avatar');
  const onboarded = session.get('onboarded');
  const loginMethod = session.get('login-method');
  const featureFlagsForAppLayoutKeys = [
    FeatureFlags.hub_admin_tools,
    FeatureFlags.hub_internal_tools,
    FeatureFlags.hub_my_packages,
    FeatureFlags.hub_renewal,
    FeatureFlags.hub_pre_lease,
    FeatureFlags.hub_admin_page,
    FeatureFlags.hub_workflows,
    FeatureFlags.spotlight_search,
  ];

  const [
    user,
    hubMetaData,
    featureFlags,
    { isMaintenanceDashboardEnabled } = { isMaintenanceDashboardEnabled: false },
    buildingSettings,
    organizationLogoUrl,
  ] = await Promise.all([
    getCurrentUser(request),
    getHubMetadata(request),
    getFeatureFlags(request, featureFlagsForAppLayoutKeys),
    getMaintenanceDashboardSettings(request),
    getBuildingSettings(request),
    getOrgLogo(request),
  ]);

  const url = new URL(request.url);

  const backgroundImage = url.searchParams.get('background') || hubMetaData.background;

  const dialogType = url.searchParams.get(
    GlobalSearchParam.DialogType,
  ) as GlobalDialogTypeUnion | null;

  let communityId = url.searchParams.get(GlobalSearchParam.CommunityId);

  if (
    // only for dialogs that require a communityId
    dialogType &&
    (
      [
        GlobalDialogType.FeaturedSlideForm,
        GlobalDialogType.DiscussionForm,
        GlobalDialogType.EventForm,
        GlobalDialogType.GeneralInfoPageForm,
        GlobalDialogType.GroupForm,
        GlobalDialogType.CreateNotification,
      ] as GlobalDialogTypeUnion[]
    ).includes(dialogType)
  ) {
    communityId = await requireCommunityIdParam(request);
  }

  const isOrganizationScope = url.pathname.includes('/organizations/');

  const computedBackgroundImage = !isValidUrl(backgroundImage)
    ? cloudinary.image(backgroundImage).quality('auto').format('webp').toURL()
    : backgroundImage;

  const managedCommunities = sortBy(user.managedCommunities, (community) =>
    community.displayName.toLowerCase(),
  );

  // if there is no community id selected via search params, or we can't find it, default to the first community
  const selectedCommunity = communityId
    ? managedCommunities.find((c) => c.id === communityId) || managedCommunities[0]
    : managedCommunities[0];

  return {
    buildingSettings,
    featureFlags,
    organizationLogoUrl,
    backgroundImage: computedBackgroundImage,
    user: {
      id: userId,
      avatar: userAvatar,
      name: getUserFullName(user?.firstName, user?.lastName),
      shortenedName: getUserShortenedName(user?.firstName, user?.lastName),
      initials: getUserInitials(user?.firstName, user?.lastName),
      email: user?.email,
      managedCommunities,
    },
    organization: selectedCommunity.organization!,
    selectedCommunity,
    hubCollateralLink: managedCommunities.find((community) => community.uiMetadata?.collateralUrl)
      ?.uiMetadata?.collateralUrl,
    showFirstTimeWalkMe: onboarded && hubMetaData.firstTimeWalkMe,
    isOrganizationScope,
    isMaintenanceDashboardEnabled,
    session: {
      loginMethod,
    },
  };
}

export default function AppLayout({ loaderData, matches }: Route.ComponentProps) {
  const { pageName, pageCategory } = useAnalytics();
  const [isSideMenuOpen] = useAtom(sideMenuOpen);
  const [isWalkMeShown, setIsWalkMeShown] = useAtom(walkMeShown);

  const isIntroPage = React.useMemo(
    () =>
      !!matches
        // @ts-expect-error layoutType is not defined on match
        .find((match) => match.handle && match.handle.layoutType == 'intro'),
    [matches],
  );

  React.useEffect(() => {
    if (loaderData.session.loginMethod !== 'IMPERSONATION') {
      void analytics.identify(loaderData.user.id, {
        name: loaderData.user.name,
        email: loaderData.user.email,
      });
    }

    Sentry.setUser({
      email: loaderData.user.email,
      id: loaderData.user.id,
      username: loaderData.user.name,
    });
  }, [
    loaderData.session.loginMethod,
    loaderData.user.email,
    loaderData.user.id,
    loaderData.user.name,
  ]);

  React.useEffect(() => {
    if (pageName) {
      void analytics.page(pageCategory, pageName);
    }
  }, [pageCategory, pageName]);

  React.useEffect(() => {
    if (window.ENV.SMARTLOOK_API_KEY) {
      Smartlook.identify(loaderData.user?.id || 'empty-user-id', {
        name: loaderData.user.name,
        email: loaderData.user.email,
      });
    }
  }, [loaderData.user.email, loaderData.user?.id, loaderData.user.name]);

  useIsomorphicLayoutEffect(() => {
    if (loaderData.showFirstTimeWalkMe && matchMedia(`(min-width: ${LG_BREAKPOINT}px)`).matches) {
      setIsWalkMeShown(true);
    }
  }, [loaderData.showFirstTimeWalkMe, setIsWalkMeShown]);

  const { fetchAndModifySVG } = useOrgLogo(
    loaderData.organizationLogoUrl.organizationLogoUrl || '',
  );

  const [logo, setLogo] = React.useState('');
  React.useEffect(() => {
    fetchAndModifySVG('#ffffff').then((data) => setLogo(data));
  }, [fetchAndModifySVG, loaderData.organizationLogoUrl.organizationLogoUrl]);

  return (
    <div
      className="relative flex min-h-0 min-w-0 flex-1 bg-black/10 bg-cover bg-center bg-blend-overlay"
      style={{
        backgroundImage: `url(${loaderData.backgroundImage})`,
      }}>
      <SideMenu />
      <div
        className={clsx(
          'flex min-h-0 min-w-0 flex-1 flex-col transition-all duration-300 ease-in-out',
          isSideMenuOpen && 'sm:pl-[298px]',
        )}>
        <header
          className={clsx(
            'flex flex-col justify-between border-white/30 px-6 py-7 sm:h-24 sm:flex-row sm:items-center sm:px-10',
            !isIntroPage && 'border-b',
          )}>
          <div className="relative flex min-h-10 w-full items-center justify-between">
            <Link
              className={clsx(isSideMenuOpen ? 'opacity-0' : 'opacity-100', 'relative z-10')}
              to="/">
              <VennLogo />
            </Link>
            {logo && (
              <div
                className="absolute flex w-full justify-center"
                dangerouslySetInnerHTML={{ __html: logo }}
              />
            )}
            <UserProfileDialogTrigger
              className={clsx(isSideMenuOpen && 'hidden sm:block')}
              id="user-profile-dialog-trigger"
              surface="dark"
            />
          </div>
        </header>
        <ContentArea>
          <Outlet />
        </ContentArea>
      </div>
      {loaderData.featureFlags.spotlight_search && <SpotlightSearch />}
      <UserProfileDialog />
      <GlobalDialog />
      <AnimatePresence>{isWalkMeShown && <WalkMeOverlay />}</AnimatePresence>
    </div>
  );
}

export function shouldRevalidate({
  defaultShouldRevalidate,
  formMethod,
  nextUrl,
  currentUrl,
}: ShouldRevalidateFunctionArgs) {
  // if the user navigates to/from an organization page, we need to revalidate the data
  if (
    (nextUrl.pathname.includes('/organizations/') &&
      !currentUrl.pathname.includes('/organizations/')) ||
    (currentUrl.pathname.includes('/organizations/') &&
      !nextUrl.pathname.includes('/organizations/'))
  ) {
    return true;
  }

  if (formMethod === 'POST') {
    return defaultShouldRevalidate;
  }

  return false;
}

export const handle = {
  breadcrumb: () => <Link to="/">Home</Link>,
  i18n: ['app-layout', 'home-page'],
  searchable: true,
};

function VennLogo() {
  return (
    <svg fill="none" height="19" viewBox="0 0 72 19" width="72" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M34.3028 10.4507C34.3322 10.0362 34.3617 9.5329 34.3617 9.05922C34.3322 3.70067 31.0635 0.0592194 26.293 0.0592194C21.2868 0.0592194 17.812 3.70067 17.812 9.05922C17.812 14.477 21.4341 18.0592 26.5285 18.0592C29.12 18.0592 31.4463 17.4375 33.5371 15.6612L31.6819 12.9967C30.2684 14.1217 28.4721 14.8026 26.7641 14.8026C24.3494 14.8026 22.1997 13.5296 21.6991 10.4507H34.3028ZM30.5629 7.60856H21.7285C22.1114 5.09211 23.5838 3.37501 26.2341 3.40461C28.6193 3.40461 30.1506 4.94409 30.5629 7.60856ZM6.83192 17.6151H11.2491L18.081 0.503307H14.135L9.0994 13.7369L4.06381 0.503307H0L6.83192 17.6151ZM52.653 6.86858V17.6153H48.9131V8.112C48.9131 5.24029 47.4408 3.64161 44.9377 3.64161C42.5524 3.64161 40.5205 5.24029 40.5205 8.17121V17.6449H36.8101V0.533052H40.491V2.63503C41.8456 0.62187 43.9659 0.0889731 45.7033 0.0889731C49.7966 0.0593678 52.653 2.87187 52.653 6.86858ZM72.0001 17.6153V6.86858C72.0001 2.87187 69.1437 0.0593678 65.021 0.0889731C63.2835 0.0889731 61.1633 0.62187 59.8087 2.63503V0.533052H56.1277V17.6449H59.8676V8.17121C59.8676 5.24029 61.8995 3.64161 64.2848 3.64161C66.7878 3.64161 68.2602 5.24029 68.2602 8.112V17.6153H72.0001Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
}

function GlobalDialog() {
  const { globalSearchParams } = useUrl();

  return (
    <ClientOnly>
      {() => {
        switch (globalSearchParams.dialogType) {
          case GlobalDialogType.CreateNotification:
            return <CreateNotificationDialog />;
          case GlobalDialogType.EditNotification:
            return <EditNotificationDialog />;
          case GlobalDialogType.EventForm:
            return <EventFormDialog />;
          case GlobalDialogType.GroupForm:
            return <GroupFormDialog />;
          case GlobalDialogType.DiscussionForm:
            return <DiscussionFormDialog />;
          case GlobalDialogType.FeaturedSlideForm:
            return <FeaturedSlideFormDialog />;
          case GlobalDialogType.ChangeBackgroundImage:
            return <ChangeBackGroundDialog />;
          case GlobalDialogType.Settings:
            return <SettingsDialog />;
          case GlobalDialogType.GeneralInfoPageForm:
            return <GeneralInfoPageFormDialog />;
          case GlobalDialogType.ShowsIntroductoryVideo:
            return <IntroductoryVideoDialog />;
          case GlobalDialogType.CreateMoveInNotification:
            return <CreateMoveInNotificationDialog />;
          case GlobalDialogType.ResidentProfile:
            return <ResidentProfile />;
          case GlobalDialogType.CreateResidentNotification:
            return <CreateResidentNotificationDialog />;
          default:
            return null;
        }
      }}
    </ClientOnly>
  );
}

function WalkMeOverlay() {
  const [, setIsWalkMeShown] = useAtom(walkMeShown);
  const fetcher = useFetcher();
  const { t } = useTranslation('app-layout');

  return (
    <motion.button
      animate={{ opacity: 1 }}
      className="fixed inset-0 z-[999] flex flex-col items-center justify-center bg-black/70 backdrop-blur-lg hover:cursor-pointer"
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      onClick={() => {
        fetcher.submit({}, { action: '/resources/first-time-walkme', method: 'POST' });

        setIsWalkMeShown(false);
      }}>
      <div className="flex flex-col items-center justify-center gap-y-10 pb-40 pr-20 text-center text-white">
        <Heading className="leading-normal" variant="h1">
          <Trans i18nKey="walkMe-button-heading" ns="app-layout">
            One more thing. We&apos;ve created some email
            <br /> templates and communication materials to
            <br />
            make it easier to share information and
            <br /> updates with your residents.
          </Trans>
        </Heading>
        {t('walkMe.button.cta')}
      </div>
    </motion.button>
  );
}
