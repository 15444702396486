import type { RefObject } from 'react';
import { useEffect } from 'react';

type Errors = Record<string, unknown>;

const useScrollToError = (
  containerRef: RefObject<HTMLElement>,
  errors: Errors | undefined,
  isPublishing: boolean = false,
  errorSelector: string = "[slot='errorMessage']",
) => {
  useEffect(() => {
    if (containerRef?.current && errors && Object.keys(errors).length > 0 && !isPublishing) {
      // Wait for errors to render in the DOM
      setTimeout(() => {
        const errorField = containerRef.current?.querySelector(errorSelector) as HTMLElement | null;

        if (errorField && containerRef.current) {
          const rect = errorField.getBoundingClientRect();
          const containerRect = containerRef.current.getBoundingClientRect();

          // Determine the scroll position dynamically
          if (rect.bottom > containerRect.bottom) {
            // If the field is below the visible area, scroll it to the bottom
            errorField.scrollIntoView({ behavior: 'smooth', block: 'end' });
          } else if (rect.top < containerRect.top) {
            // If the field is above the visible area, scroll it to the top
            errorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            // If it's in view, no need to scroll
            errorField.focus?.();
          }
        } else {
          // Fallback: Scroll to the top of the container
          containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }, 0); // Allow DOM updates to complete
    }
  }, [containerRef, errors, errorSelector, isPublishing]);
};

export default useScrollToError;
