import { useEffect, useRef, useState } from 'react';
import type { ValueOf } from 'type-fest';
import type { FormSearchParams } from '~/dto/notifications-form-dto';
import type { SearchNavigateValue } from '~/utils/search-params';
import { useSearchNavigate } from '~/utils/search-params';

type AnimationStatus = 'unmounted' | 'hidden' | 'visible';

export const useDialogAnimation = ({
  isMobile,
  getClearedSearchParams,
}: {
  isMobile: boolean;
  getClearedSearchParams: () => Record<ValueOf<typeof FormSearchParams>, SearchNavigateValue>;
}) => {
  const searchNavigate = useSearchNavigate();
  const [animation, setAnimation] = useState<AnimationStatus>('unmounted');
  const mountedRef = useRef(false);
  const animationDurationEnter = 0.3;
  const animationDurationExit = 0.2;

  function handleAnimationComplete(variant: AnimationStatus) {
    if (mountedRef.current && animation === 'unmounted') return;
    // only after the initial animation is complete, we can set the mountedRef to true
    if (variant === 'visible') {
      mountedRef.current = true;
    }
    setAnimation((prevAnimation) =>
      // if the active variant is hidden and the previous animation was hidden, we can safely unmount the component
      variant === 'hidden' && prevAnimation === 'hidden' ? 'unmounted' : 'visible',
    );
  }

  useEffect(() => {
    // if this is not the first render and the animation state is unmounted,
    // it means that the user attempted to go back to the notifications page
    if (isMobile && mountedRef.current && animation === 'unmounted') {
      searchNavigate({
        ...getClearedSearchParams(),
      });
    }
  }, [animation, searchNavigate]);

  return {
    animation,
    setAnimation,
    handleAnimationComplete,
    animationDurationEnter,
    animationDurationExit,
  };
};
