import { Icon, Pressable } from '@venncity/block';
import { ModalOverlay, Modal, Dialog } from 'react-aria-components';
import { useSearchNavigate } from '~/utils/search-params';

export default function IntroductoryVideoDialog() {
  const searchNavigate = useSearchNavigate();

  return (
    <ModalOverlay
      className="fixed inset-0 z-50 flex h-[var(--visual-viewport-height)] w-screen items-center justify-center "
      defaultOpen
      isDismissable
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          searchNavigate(
            {
              dialogType: undefined,
            },
            { preventScrollReset: true },
          );
        }
      }}
      style={{ backgroundColor: 'rgba(62, 58, 55, .4)' }}>
      <Modal className="relative aspect-video w-2/3">
        <Dialog
          aria-label="Venn Onboarding Video"
          className="shadow-8 overflow-hidden rounded-2xl bg-black outline-none">
          {({ close }) => (
            <div>
              <Pressable
                className="absolute -right-4 -top-14 px-4 py-4 text-white outline-none"
                onPress={close}>
                <Icon name="close" />
              </Pressable>
              <iframe
                allow="autoplay; fullscreen; picture-in-picture"
                className="aspect-video"
                height="100%"
                src="https://player.vimeo.com/video/927225705?autoplay=1&loop=1&autopause=0"
                title="Introductory video"
                width="100%"></iframe>
            </div>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}
