import { useSearchParams } from 'react-router';
import { Spin } from '@venncity/venn-ds';
import invariant from 'tiny-invariant';
import type { loader } from '~/routes/resources+/featured-slides+/$id.edit';
import { useResourceData } from '~/utils/http';
import FeaturedSlideForm from './featured-slide-form';

export function EditFeaturedSlideForm({ communityId }: { communityId?: string | null }) {
  const [searchParams] = useSearchParams();
  invariant(communityId, 'Community id must be defined');
  const featuredSlideId = searchParams.get('featured-slide-id');
  const { data } = useResourceData<typeof loader>(
    `resources/featured-slides/${featuredSlideId}/edit?community-id=${communityId}`,
  );

  return data?.featuredSlide ? (
    <FeaturedSlideForm initialValues={data?.featuredSlide} title="Edit Post" />
  ) : (
    <div className="flex flex-1 flex-col items-center justify-center">
      <Spin />
    </div>
  );
}
