import { Alert, Text } from '@venncity/block';
import { AnimatePresence, motion } from 'motion/react';
import { useEffect, useState } from 'react';

const ADD_SENDER_FORM_URL = 'https://forms.monday.com/forms/72ee55b06b84691e00e09194a5aad085';
export const DEFAULT_SENDER_EMAIL = 'hello@venn.city';
export const DEFAULT_SENDER = `Venn <${DEFAULT_SENDER_EMAIL}>`;

type SenderEmailProps = {
  fromName: string;
  fromEmail: string;
  isOpen: boolean;
};

export function SenderEmailBanner({ fromName, fromEmail, isOpen }: SenderEmailProps) {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            initial={hasMounted ? { opacity: 0, height: 0 } : false}
            onAnimationComplete={() => {
              window.dispatchEvent(
                new CustomEvent('enable-notifications-banner:animation-complete'),
              );
            }}
            transition={{ delay: 0.5 }}>
            <Alert
              className="border-grey-300 sticky top-0 z-50 flex items-center justify-between gap-2 border-b bg-white"
              description={`<${fromEmail}>`}
              icon="mail"
              title={`Email from ${fromName}`}
              variant="default">
              {fromEmail == DEFAULT_SENDER_EMAIL && (
                <Text variant="p5">
                  <div className="flex gap-2">
                    Want to set up your email?
                    <a
                      className="underline"
                      href={ADD_SENDER_FORM_URL}
                      rel="noopener noreferrer"
                      target="_blank">
                      Reach support
                    </a>
                  </div>
                </Text>
              )}
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
