import { Accordion, Icon, Text } from '@venncity/block';
import { Divider } from '@venncity/venn-ds';
import { AnimatePresence, motion } from 'motion/react';
import { useTranslation } from 'react-i18next';
import { enumUserTaskStatus } from '~/genql';
import type { TaskListsDTO } from '../activity-tab-dto';
import { Section } from '../data-point-section';

export const MoveInChecklist = ({ taskLists }: { taskLists: TaskListsDTO[] }) => {
  const { t } = useTranslation('resident-profile');

  return (
    <Section title={t('move-in-checklist.title')}>
      <div className="flex flex-col px-6 py-4">
        {taskLists.length > 0 ? (
          taskLists?.map((taskList: TaskListsDTO) => (
            <Accordion defaultExpandedKeys={['ACTIVE']} key={taskList.id}>
              <Accordion.Item
                className="flex flex-col gap-2 pt-2"
                id={taskList.id}
                key={taskList.id}>
                {({ isExpanded }) => {
                  return (
                    <>
                      <AnimatePresence>
                        <Accordion.Header className="pb-2 pt-1" isExpanded={isExpanded}>
                          <div className="flex space-x-8">
                            <div className="flex flex-col gap-2">
                              <Text className="text-start" variant="p4" weight="medium">
                                {t('move-in-checklist.unit')}
                              </Text>
                              <Text className="text-start" variant="p4">
                                {taskList.unitName}
                              </Text>
                            </div>
                            <div className="flex flex-col gap-2">
                              <Text className="text-start" variant="p4" weight="medium">
                                {t('move-in-checklist.status')}
                              </Text>
                              <Text className="text-start" variant="p4">
                                {taskList.status}
                              </Text>
                            </div>
                          </div>
                        </Accordion.Header>
                        {!isExpanded && <Divider marginless />}
                        <Accordion.Panel>
                          <motion.div
                            animate={{ height: 'auto', opacity: 1 }}
                            className="overflow-hidden"
                            exit={{ opacity: 0, height: 0 }}
                            initial={{ height: 0, marginTop: 0, opacity: 1 }}
                            transition={{ bounce: 0.5, ease: 'circInOut' }}>
                            <div className="flex flex-col gap-2">
                              {taskList.tasks?.map((task) => (
                                <div
                                  className="bg-grey-100 flex items-center rounded-s p-2"
                                  key={task.id}>
                                  {task.status === enumUserTaskStatus.COMPLETED ? (
                                    <Icon className="text-positive-400 mr-2 h-4 w-4" name="check" />
                                  ) : (
                                    <Icon className="mr-2 h-4 w-4 opacity-60" name="time" />
                                  )}
                                  {task.name}
                                </div>
                              ))}
                              {isExpanded && <Divider marginless />}
                            </div>
                          </motion.div>
                        </Accordion.Panel>
                      </AnimatePresence>
                    </>
                  );
                }}
              </Accordion.Item>
            </Accordion>
          ))
        ) : (
          <Text className="flex h-24 w-full items-center justify-center" variant={'p5'}>
            {t('empty-state.move-in-checklist')}
          </Text>
        )}
      </div>
    </Section>
  );
};
