import * as ScrollArea from '@radix-ui/react-scroll-area';
import { isFunction } from 'lodash-es';
import React from 'react';
import { Placement } from 'react-aria';
import { Group, ListBoxItem, ListBox, Key } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { Popover } from '../../atoms/popover/popover';
import { text } from '../../electrons/text';

const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const minutes = ['00', '15', '30', '45'];

type SelectionKeys = 'hour' | 'minute' | 'period';

export function TimeSelection({
  defaultValue,
  onSelectionChange,
  placement = 'top end',
  offset = 1,
  shouldSync = false,
}: {
  defaultValue?: string; // EXAMPLE: 19:20:30
  onSelectionChange?: (time: string) => void;
  placement?: Placement;
  offset?: number;
  shouldSync?: boolean;
}) {
  const { hour, minute, period } = React.useMemo(() => {
    if (!defaultValue) return {};

    const [hour, minute] = defaultValue.split(':');

    return {
      hour: toHourStep(hour),
      minute: toMinuteStep(minute),
      period: Number(hour) > 11 ? 'PM' : 'AM',
    };
  }, [defaultValue]);

  const [selectedHourKeys, setSelectedHourKeys] = React.useState<Iterable<Key>>(hour ? [hour] : []);

  const [selectedMinuteKeys, setSelectedMinuteKeys] = React.useState<Iterable<Key>>(
    minute ? [minute] : [],
  );

  const [selectedPeriodKeys, setSelectedPeriodKeys] = React.useState<Iterable<Key>>(
    period ? [period] : [],
  );

  React.useEffect(() => {
    if (!shouldSync) return;

    if (hour) setSelectedHourKeys([hour]);
    if (minute) setSelectedMinuteKeys([minute]);
    if (period) setSelectedPeriodKeys([period]);
  }, [hour, minute, period, shouldSync]);

  function handleSelectionChange({ key, value }: { key: SelectionKeys; value: Iterable<Key> }) {
    if (!isFunction(onSelectionChange)) return;

    let hour = Array.from(selectedHourKeys).join('');
    let minute = Array.from(selectedMinuteKeys).join('');
    let period = Array.from(selectedPeriodKeys).join('');

    if (key === 'hour') {
      hour = Array.from(value).join('');
      setSelectedHourKeys(value);
    }
    if (key === 'minute') {
      minute = Array.from(value).join('');
      setSelectedMinuteKeys(value);
    }

    if (key === 'period') {
      period = Array.from(value).join('');
      setSelectedPeriodKeys(value);
    }

    const computedHour =
      period === 'AM' && hour === '12'
        ? '00'
        : period === 'PM' && hour !== '12'
          ? String(Number(hour) + 12)
          : hour;

    onSelectionChange(`${computedHour}:${minute}:00`);
  }

  const listBoxStyle =
    'border-grey-300 flex-1 overflow-y-auto scroll-x-none border-r-[1px] overflow-x-hidden';

  const listBoxItemStyle =
    'rac-selected:bg-grey-200 rac-hover:text-grey-600 w-[75px] h-[52px] flex h-11 items-center justify-center hover:cursor-pointer outline-none';

  return (
    <Popover
      className="border-grey-300 rounded-lg border-[1px]"
      offset={offset}
      placement={placement}>
      <Group
        className={twMerge(
          text({ variant: 'p4', weight: 'medium' }),
          'flex h-52 w-[225px] overflow-x-hidden text-center',
        )}>
        <ScrollArea.Root className="overflow-hidden rounded">
          <ScrollArea.Viewport className="h-full w-full">
            <ListBox
              aria-label="hours"
              className={listBoxStyle}
              onSelectionChange={(value) => {
                handleSelectionChange({ key: 'hour', value });
              }}
              selectedKeys={selectedHourKeys}
              selectionMode="single">
              {hours.map((hour) => (
                <ListBoxItem
                  className={listBoxItemStyle}
                  id={hour}
                  key={hour}
                  textValue={hour.toString()}>
                  {hour}
                </ListBoxItem>
              ))}
            </ListBox>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            className="flex !touch-auto select-none bg-transparent p-0.5 transition-colors duration-150 ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
            orientation="vertical">
            <ScrollArea.Thumb className="bg-grey-300 relative max-h-[25%] flex-1 rounded-[10px]" />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
        <ListBox
          aria-label="minute"
          className={listBoxStyle}
          onSelectionChange={(value) => {
            handleSelectionChange({ key: 'minute', value });
          }}
          selectedKeys={selectedMinuteKeys}
          selectionMode="single">
          {minutes.map((minute) => (
            <ListBoxItem
              className={listBoxItemStyle}
              id={minute}
              key={minute}
              textValue={minute.toString()}>
              {minute}
            </ListBoxItem>
          ))}
        </ListBox>
        <ListBox
          aria-label="period"
          className="mt-auto flex-1"
          onSelectionChange={(value) => {
            handleSelectionChange({ key: 'period', value });
          }}
          selectedKeys={selectedPeriodKeys}
          selectionMode="single">
          <ListBoxItem className={listBoxItemStyle} id="AM" key={'AM'}>
            AM
          </ListBoxItem>
          <ListBoxItem className={listBoxItemStyle} id="PM" key={'PM'}>
            PM
          </ListBoxItem>
        </ListBox>
      </Group>
    </Popover>
  );
}

function toMinuteStep(input: string) {
  // Convert the input string to a number
  const num = parseInt(input, 10);

  // Find the closest reference point
  let closestPoint = minutes[0];
  let smallestDifference = Math.abs(num - Number(closestPoint));

  for (let i = 1; i < minutes.length; i++) {
    const currentPoint = minutes[i];
    const currentDifference = Math.abs(num - Number(currentPoint));

    if (currentDifference < smallestDifference) {
      smallestDifference = currentDifference;
      closestPoint = currentPoint;
    }
  }

  return closestPoint;
}

function toHourStep(input: string) {
  return input === '00' ? 12 : Number(input) > 12 ? Number(input) - 12 : Number(input);
}
