import { Text } from '@venncity/block';
import { useTranslation } from 'react-i18next';
import { Section } from '../data-point-section';

export const ResidentSatisfaction = () => {
  const { t } = useTranslation('resident-profile');

  return (
    <Section title={t('resident-satisfaction.title')}>
      <div className="p-4">
        <Text className="flex h-24 items-center justify-center italic">{t('coming-soon')}</Text>
      </div>
    </Section>
  );
};
