import { useFetcher, useFetchers, useLoaderData, useNavigate, useSearchParams } from 'react-router';
import {
  Dialog,
  Drawer,
  Text,
  TextAreaField,
  RichText,
  Icon,
  Tooltip,
  ActionMenu,
} from '@venncity/block';
import { ClickableTooltipTrigger } from '@venncity/block/atoms/tooltip/tooltip';
import { Spin, Button, Avatar } from '@venncity/venn-ds';
import clsx from 'clsx';
import { isEmpty, transform, isEqual, compact } from 'lodash-es';
import { motion } from 'motion/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValueOf } from 'type-fest';
import { BroadcastCombinationMedium, FormSearchParams } from '~/dto/notifications-form-dto';
import type {
  NotificationsFormInitialValuesDTO,
  BroadcastMediumOption,
} from '~/dto/notifications-form-dto';
import type { Building, CommunityAudienceParams } from '~/genql';
import { enumNotificationMedium } from '~/genql';
import { useAnalytics } from '~/lib/analytics';
import { cloudinary } from '~/lib/cloudinary';
import { AudienceSelector } from '~/routes/_app+/_layout/helpers/components/notifications/selectors/audience-selector';
import { MediumSelector } from '~/routes/_app+/_layout/helpers/components/notifications/selectors/medium-selector';
import {
  DEFAULT_SENDER,
  SenderEmailBanner,
} from '~/routes/_app+/_layout/helpers/components/notifications/sender-email-banner';
import useScrollToError from '~/utils/hooks/useScrollToError';
import { type ActionResponseBody } from '~/utils/http.server';
import type { SearchNavigateValue } from '~/utils/search-params';
import { GlobalSearchParam, useSearchNavigate } from '~/utils/search-params';
import { isValidUrl } from '~/utils/string';
import type { loader } from '../../..';
import type { ApplicationPageType } from './deep-link';
import { DeepLink } from './deep-link';
import { useDialogAnimation } from './hooks/useDialogAnimation';
import { useIsMobile } from './hooks/useIsMobile';
import { ScheduledSend } from './scheduled-send';
import { CommunitySelector } from './selectors/community-selector';

enum SubmitAction {
  draft = 'draft',
  send = 'send',
  schedule = 'schedule',
  test = 'test',
}

interface NotificationFormDialogProps {
  title: string;
  notificationId?: string;
  initialValues?: NotificationsFormInitialValuesDTO;
  emailSettings?: { fromEmails: string[] };
  nextPageParams?: Record<string, string>;
  withActionMenu?: boolean;
}

type FormState = {
  medium: string;
  audience: string | undefined;
  communityIds: string[];
  title: string | undefined;
  message: string | undefined;
  imageResourceId: string | undefined;
  buildings: Pick<Building, 'id' | 'name'>[] | undefined;
  event: string | undefined;
  deepLink: {
    enabled: boolean;
    applicationPage: string | undefined;
    entityId: string | undefined | null;
  };
  residents: { id: string; name?: string }[] | undefined;
  scheduled: {
    hasScheduledBroadcast: boolean;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- remove this and fix code
  [key: string]: any;
};

type StateValues = {
  [key: string]: string | string[];
};

type FormDataValues = {
  [key: string]: string | string[];
};

interface NotificationFormProps extends NotificationFormDialogProps {
  searchNavigate: ReturnType<typeof useSearchNavigate>;
  getClearedSearchParams: () => Record<ValueOf<typeof FormSearchParams>, SearchNavigateValue>;
  isMobile: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- remove this and fix code
  state: any;
  formRef: React.RefObject<HTMLFormElement>;
  formActionUrl: string;
  searchParams: URLSearchParams;
  initialAudienceValue: string | undefined;
  onBackAction: () => void;
  withActionMenu?: boolean;
  emailSettings?: { fromEmails: string[] };
}

export default function NotificationFormDialog({
  initialValues,
  notificationId,
  title,
  emailSettings,
  nextPageParams,
  withActionMenu,
}: NotificationFormDialogProps) {
  const [searchParams] = useSearchParams();
  const fetcher = useFetcher<ActionResponseBody<{ id: string; intent: SubmitAction }>>();
  const formActionUrl = `/resources/notifications/${notificationId ? `${notificationId}/edit` : 'new'}`;
  const isMobile = useIsMobile();
  const searchNavigate = useSearchNavigate();
  const formRef = React.useRef<HTMLFormElement>(null);
  const dialogRef = React.useRef<HTMLDivElement>(null);
  const {
    animation,
    setAnimation,
    handleAnimationComplete,
    animationDurationEnter,
    animationDurationExit,
  } = useDialogAnimation({
    isMobile,
    getClearedSearchParams,
  });

  useScrollToError(dialogRef, fetcher.data?.errors || {});

  const initialAudienceValue = initialValues?.audience;

  const initialEventId =
    initialValues?.audience === 'EVENT_TYPE' ? initialValues?.audienceParams?.eventId : undefined;

  const initialGroup =
    initialValues?.audience === 'INTEREST_GROUP'
      ? initialValues.audienceParams.interestGroupIds
      : undefined;

  const initialResidents =
    initialValues?.audience === 'SPECIFIC_MEMBERS' ? initialValues?.residents : undefined;

  const initialCommunities =
    initialValues?.audience === 'COMMUNITY'
      ? initialValues?.audienceParams?.communityIds
      : undefined;

  let initialMedium = null;
  const mediumFromParams = searchParams.get(FormSearchParams.Channel);
  if (mediumFromParams) {
    initialMedium = mediumFromParams as BroadcastMediumOption;
  } else if (initialValues?.medium) {
    initialMedium = initialValues?.medium;
  }

  const state: FormState = {
    medium: initialMedium || '',
    audience: notificationId
      ? searchParams.get(FormSearchParams.Audience) || initialAudienceValue
      : '',
    hood: searchParams.get(FormSearchParams.Hood),
    communityIds: notificationId
      ? initialCommunities || []
      : compact(searchParams.getAll(GlobalSearchParam.CommunityId)),
    title: initialValues?.title,
    message: initialValues?.message,
    imageResourceId: initialValues?.imageId,
    buildings: initialValues?.buildings,
    group: initialGroup ? initialGroup[0] : searchParams.get('group-id'),
    event: searchParams.get(FormSearchParams.Event) || initialEventId,
    deepLink: {
      enabled: Boolean(
        Number(searchParams.get(FormSearchParams.HasDeepLink)) ||
          !!initialValues?.deepLink?.applicationPage,
      ),
      applicationPage:
        searchParams.get(FormSearchParams.ApplicationPage) ||
        initialValues?.deepLink?.applicationPage,
      entityId: searchParams.get(FormSearchParams.DeepLinkId) || initialValues?.deepLink?.entityId,
    },
    residents: initialResidents || undefined,
    scheduled: {
      hasScheduledBroadcast:
        Boolean(Number(searchParams.get(FormSearchParams.ScheduledMessage))) ||
        !isEmpty(initialValues?.scheduledBroadcast),
    },
  };

  function getClearedSearchParams() {
    const initialValue = {} as Record<ValueOf<typeof FormSearchParams>, SearchNavigateValue>;

    const clearedParams = transform(
      FormSearchParams,
      (result, value) => {
        result[value] = undefined;
        return result;
      },
      initialValue,
    );

    return {
      ...clearedParams,
      ...(nextPageParams ?? {}),
    };
  }

  const onBackAction = () => {
    if (!state?.scheduled?.hasScheduledBroadcast && formRef?.current) {
      const formData = new FormData(formRef?.current);
      const isFormChanged = hasAnyFieldChanged({ state, formData, initialValues });
      if (isFormChanged) {
        formData.append('intent', SubmitAction.draft);

        fetcher.submit(formData, {
          method: 'POST',
          action: formActionUrl,
        });
      }
      if (fetcher.data?.data?.intent !== SubmitAction.test) {
        searchNavigate({
          ...getClearedSearchParams(),
        });
      }
    }
    if (fetcher.data?.data?.intent !== SubmitAction.test) {
      searchNavigate({
        ...getClearedSearchParams(),
      });
    }
    if (isMobile) {
      setAnimation('hidden');
      window.dispatchEvent(new Event('showDetailsDialog'));
    }
  };

  const notificationFormProps = {
    state,
    formRef,
    initialValues,
    formActionUrl,
    searchParams,
    searchNavigate,
    getClearedSearchParams,
    isMobile,
    initialAudienceValue,
    title,
    onBackAction,
    emailSettings,
    notificationId,
    withActionMenu,
  };

  return isMobile ? (
    <Dialog
      aria-label="notification-form-dialog"
      className={clsx(
        'absolute z-[999] flex max-h-screen min-h-0 w-full flex-col overflow-y-hidden outline-none',
        'md:max-h-auto md:static md:h-auto md:overflow-auto',
      )}
      ref={dialogRef}>
      <motion.div
        animate={animation}
        className="flex h-full min-h-0 flex-col overflow-auto bg-white"
        initial="hidden"
        onAnimationComplete={handleAnimationComplete}
        variants={{
          hidden: {
            opacity: 0,
            x: '100%',
            transition: {
              bounce: 0,
              duration: animationDurationExit,
              ease: 'easeOut',
            },
          },
          visible: {
            opacity: 1,
            x: 0,
            transition: {
              bounce: 0,
              duration: animationDurationEnter,
              ease: 'easeIn',
            },
          },
        }}>
        <NotificationForm {...notificationFormProps} />
      </motion.div>
    </Dialog>
  ) : (
    <Drawer defaultOpen isDismissable onOpenChange={onBackAction}>
      <Dialog ref={dialogRef}>
        <NotificationForm {...notificationFormProps} />
      </Dialog>
    </Drawer>
  );
}

const NotificationForm = ({
  initialValues,
  title,
  notificationId,
  searchNavigate,
  getClearedSearchParams,
  isMobile,
  state,
  formRef,
  formActionUrl,
  searchParams,
  initialAudienceValue,
  onBackAction,
  withActionMenu,
  emailSettings,
}: NotificationFormProps) => {
  const loaderData = useLoaderData<typeof loader>();
  const { actionClicked } = useAnalytics();
  const fetcher = useFetcher<ActionResponseBody<{ id: string; intent: SubmitAction }>>();
  const fetchers = useFetchers();
  const navigate = useNavigate();
  const { t } = useTranslation(['notification-form']);

  const isScheduledBroadcast =
    Boolean(Number(searchParams.get(FormSearchParams.ScheduledMessage))) ||
    !isEmpty(initialValues?.scheduledBroadcast);

  const isPending = fetchers.some(
    (fetcher) => fetcher.formMethod === 'POST' && ['loading', 'submitting'].includes(fetcher.state),
  );

  function extractNameFromEmail(email: string): [string, string] {
    // extract "Jon Smith <john.s@venncity>" to ["Jon Smith", "john.s@venncity"]
    const matches = email.match(/(.*)<(.*)>/);
    if (matches) {
      return [matches[1].trim(), matches[2].trim()];
    } else {
      return [email, email];
    }
  }

  const customFromEmail = emailSettings?.fromEmails?.[0];
  const [fromName, fromEmail] = extractNameFromEmail(customFromEmail || DEFAULT_SENDER);

  //hide community combobox for sending move-in notifications
  const hideCommunitySelector = searchParams.has('move-in-status');

  useEffect(() => {
    const payload = fetcher.data;
    if (payload?.success) {
      const clearSearchParams = getClearedSearchParams();
      if (payload?.data?.intent === SubmitAction.send) {
        actionClicked({
          target: null,
          element: 'Send Communication',
        });
      }
      if (payload?.data?.intent === SubmitAction.test) {
        actionClicked({
          target: null,
          element: 'Send Test Communication',
        });
      } else {
        searchNavigate({
          ...clearSearchParams,
          'building-ids': undefined,
        });
      }
    }
  }, [fetcher.data, notificationId, searchNavigate]);

  const enableCtaForAllMediums = loaderData.featureFlags?.hub_enable_communication_email_cta;
  const isEmailBannerOpen = state.medium.includes(
    enumNotificationMedium.EMAIL,
    BroadcastCombinationMedium.APP_AND_EMAIL,
  );

  return (
    <>
      {!notificationId || (initialValues && notificationId) ? (
        <fetcher.Form
          action={formActionUrl}
          className="flex h-full flex-col"
          method="POST"
          ref={formRef}>
          <Drawer.Header
            title={title}
            {...(isMobile && {
              onBackAction,
            })}>
            {withActionMenu && (
              <ActionMenu
                onAction={(key) => {
                  if (key == 'delete' || key == 'remove') {
                    const newParams = new URLSearchParams(searchParams.toString());
                    newParams.set('modal-action', 'delete-notification');
                    newParams.delete('dialog-type');
                    navigate(
                      { pathname: '/communication', search: newParams.toString() },
                      {
                        replace: true,
                        state: {
                          title: t('delete-modal.title'),
                          text: t('delete-modal.text'),
                          confirmButtonText: t('delete-modal.confirm'),
                        },
                      },
                    );
                  }
                }}
                selectionMode="single"
                size="small">
                <ActionMenu.Item
                  id={'delete'}
                  slotLeft={<Icon name="trash" />}
                  variant={'destructive'}>
                  {t('cta.delete')}
                </ActionMenu.Item>
              </ActionMenu>
            )}
            {isMobile && (
              <SendButton
                isDisabled={
                  (fetcher.data?.data?.intent === SubmitAction.send ||
                    fetcher.data?.data?.intent === SubmitAction.schedule) &&
                  isPending
                }
                isScheduledBroadcast={isScheduledBroadcast}
              />
            )}
          </Drawer.Header>
          <div className="overflow-y-auto">
            <input name="from-email" type="hidden" value={fromEmail} />
            <input name="from-name" type="hidden" value={fromName} />
            <SenderEmailBanner
              fromEmail={fromEmail}
              fromName={fromName}
              isOpen={isEmailBannerOpen}
            />
            <Dialog.Body className="flex h-auto max-w-xl flex-1 flex-col space-y-6  md:px-4 md:py-4">
              {!fetcher.data?.success && fetcher.data?.message && (
                <Text className="text-negative-400 py-4" variant="p5">
                  {fetcher.data?.message}
                </Text>
              )}
              <div className="flex gap-x-2">
                <Chip>
                  <Avatar
                    className="-m-1 h-8 w-8"
                    size="small"
                    src={
                      isValidUrl(loaderData.user.avatar)
                        ? loaderData.user.avatar
                        : cloudinary.image(loaderData.user.avatar).toURL()
                    }
                    username={loaderData.user.name}
                  />
                  <Text className="text-grey-900" variant="p5">
                    {loaderData.user.shortenedName}
                  </Text>
                </Chip>
              </div>
              <input name="user-device" type="hidden" value={isMobile ? 'mobile' : 'desktop'} />
              <MediumSelector selectedMedium={state.medium} />
              <CommunitySelector
                className={hideCommunitySelector ? 'hidden' : ''}
                communitySelected={state.communityIds}
                errorMessage={fetcher.data?.errors.communityIds}
              />
              <AudienceSelector
                communityIds={state.communityIds}
                defaultSelectedBuildings={state.buildings?.map(
                  (building: { id: string }) => building.id,
                )}
                defaultSelectedEvent={state.event}
                defaultSelectedInterestGroup={state.group}
                defaultSelectedKey={initialAudienceValue}
                defaultSelectedResidents={
                  searchParams.get('resident-id')
                    ? [searchParams.get('resident-id')]
                    : state.residents?.map((resident: { id: string }) => resident.id)
                }
                errorMessage={
                  fetcher.data?.errors.audience?.[0] ||
                  fetcher.data?.errors.event?.[0] ||
                  fetcher.data?.errors.buildings?.[0] ||
                  fetcher.data?.errors.userIds?.[0] ||
                  fetcher.data?.errors.audienceSize?.[0]
                }
                key={`audience-selectors-${state.communityIds}`}
              />
              <TextAreaField
                defaultValue={state.title}
                errorMessage={fetcher.data?.errors.title?.[0]}
                key={`title-${state.title}`}
                label="*Subject"
                maxLength={60}
                name="title"
                placeholder="Type your subject..."
              />
              <RichText
                defaultValue={state.message}
                errorMessage={fetcher.data?.errors.message?.[0]}
                key={`message-${state.message}`}
                label="*Message"
                name="message"
                placeholder="Type your message here... "
              />
              <div className="space-y-2">
                <Text weight="bold">More options</Text>
                {(enableCtaForAllMediums || state.medium === enumNotificationMedium.MOBILE_APP) && (
                  <DeepLink
                    communityIds={state.communityIds}
                    applicationPage={state.deepLink.applicationPage as ApplicationPageType}
                    entityId={state.deepLink.entityId || ''}
                    errorMessage={
                      fetcher.data?.errors.deepLinkType?.[0] ||
                      fetcher.data?.errors.deepLinkEntityId?.[0]
                    }
                    hasDeepLinkEnabled={state.deepLink.enabled}
                    key={state.communityIds}
                  />
                )}
                <ScheduledSend
                  defaultScheduledBroadcast={initialValues?.scheduledBroadcast}
                  key={initialValues?.scheduledBroadcast?.startDate}
                />
              </div>
              <div className="flex justify-center pb-6 md:hidden">
                <SendYourselfATestButton
                  className="md:hidden"
                  isDisabled={fetcher.data?.data?.intent === SubmitAction.test && isPending}
                />
              </div>
            </Dialog.Body>
          </div>
          <Drawer.Footer className="hidden justify-end md:flex">
            <SendYourselfATestButton
              className="hidden md:block"
              isDisabled={fetcher.data?.data?.intent === SubmitAction.test && isPending}
            />
            <SendButton
              isDisabled={
                (fetcher.data?.data?.intent === SubmitAction.send ||
                  fetcher.data?.data?.intent === SubmitAction.schedule) &&
                isPending
              }
              isScheduledBroadcast={isScheduledBroadcast}
            />
          </Drawer.Footer>
        </fetcher.Form>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <Spin />
        </div>
      )}
    </>
  );
};

//temporarily added and will be replaced when email notificaiton will be supported
type ChipProps = {
  children: React.ReactNode;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
};

function Chip({ children, iconLeft, iconRight }: ChipProps) {
  return (
    <div
      className="text-p5 bg-grey-200 flex h-10 w-fit min-w-0 items-center gap-x-2 rounded-full px-2 py-1"
      role="presentation">
      {iconLeft}
      {children}
      {iconRight}
    </div>
  );
}

const hasAnyFieldChanged = ({
  state,
  formData,
  initialValues,
}: {
  state: FormState;
  formData: FormData;
  initialValues?: NotificationsFormInitialValuesDTO;
}) => {
  const stringKeys = {
    title: 'title',
    message: 'message',
    audience: 'audience',
    imageResourceId: 'image-attachment',
    event: 'event',
    deepLinkType: 'deep-link-type',
    deepLinkEntityId: 'deep-link-id',
  };

  const arrayKeys = {
    buildings: 'buildings',
    residents: 'residents',
  };

  if (state.medium && initialValues?.medium !== state.medium) {
    return true;
  }

  if (
    formData.get(stringKeys.audience) === 'COMMUNITY' &&
    !isEqual(
      (initialValues?.audienceParams as CommunityAudienceParams).communityIds,
      compact(formData.getAll('community-id')) as string[],
    )
  ) {
    return true;
  }

  const stateValues: StateValues = {};
  const formDataValues: FormDataValues = {};

  for (const [stateKey, formDataKey] of Object.entries(stringKeys)) {
    if (stateKey === 'deepLinkType') {
      stateValues[stateKey] = state.deepLink?.applicationPage || '';
    } else if (stateKey === 'deepLinkEntityId') {
      stateValues[stateKey] = state.deepLink?.entityId || '';
    } else {
      stateValues[stateKey] = state[stateKey] || '';
    }
    formDataValues[stateKey] = (formData.get(formDataKey) as string) || '';
  }

  for (const [stateKey, formDataKey] of Object.entries(arrayKeys)) {
    stateValues[stateKey] = state[stateKey]?.map((item: { id: string }) => item.id) || [];
    formDataValues[stateKey] =
      formData.getAll(formDataKey)?.map((value) => (value as string) || '') || [];
  }

  return Object.keys(stateValues).some((key) => !isEqual(formDataValues[key], stateValues[key]));
};

const SendButton = React.forwardRef<
  HTMLButtonElement,
  { isDisabled: boolean; isScheduledBroadcast: boolean }
>(({ isDisabled, isScheduledBroadcast }) => {
  return (
    <Button
      disabled={isDisabled}
      htmlType="submit"
      loading={isDisabled}
      name="intent"
      type="primary"
      value={isScheduledBroadcast ? SubmitAction.schedule : SubmitAction.send}>
      {isScheduledBroadcast ? 'Schedule' : 'Send'}
    </Button>
  );
});

SendButton.displayName = 'SendButton';

const SendYourselfATestButton = React.forwardRef<
  HTMLButtonElement,
  {
    className?: string;
    isDisabled: boolean;
  }
>(({ className, isDisabled }) => {
  const [isTestButtonTooltipOpen, setIsTestButtonTooltipOpen] = React.useState(false);

  return (
    <div className="relative">
      <Button
        className="md:mr-2"
        disabled={isDisabled}
        htmlType="submit"
        icon={<Icon className="h-4 w-4" name="help-circle" />}
        iconPosition="end"
        loading={isDisabled}
        name="intent"
        value={SubmitAction.test}>
        <span>Send yourself a test</span>
      </Button>

      <span className="absolute right-5 top-1/2 z-50 flex -translate-y-1/2 align-middle md:right-7">
        <ClickableTooltipTrigger
          buttonComponent={<div className="h-4 w-4"></div>}
          isOpen={isTestButtonTooltipOpen}
          setIsOpen={setIsTestButtonTooltipOpen}>
          <Tooltip className={clsx('ml-1', className)} placement="top start" variant="light">
            Preview the app notification on your device to see exactly how residents will receive
            it. Make sure to allow app push notifications.
          </Tooltip>
        </ClickableTooltipTrigger>
      </span>
    </div>
  );
});

SendYourselfATestButton.displayName = 'SendYourselfATestButton';
