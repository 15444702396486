import { Resize } from '@cloudinary/url-gen/actions';
import { useSearchParams } from 'react-router';
import { Icon, Dialog, Heading, Text } from '@venncity/block';
import { Button, Spin, Divider } from '@venncity/venn-ds';
import { GroupFormTabs } from '~/dto/group-form-dto';
import { cloudinary } from '~/lib/cloudinary';
import type { loader } from '~/routes/resources+/groups+/$id.preview';
import { useResourceData } from '~/utils/http';
import { GlobalSearchParam, useSearchNavigate } from '~/utils/search-params';

export function GroupPreview() {
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('group-id') || '';
  const communityId = searchParams.get(GlobalSearchParam.CommunityId);
  const searchNavigate = useSearchNavigate();
  const { data } = useResourceData<typeof loader>(
    `resources/groups/${groupId}/preview?${GlobalSearchParam.CommunityId}=${communityId}`,
  );

  return (
    <Dialog>
      <Dialog.Header showCloseButton={false} title="Group preview" />
      <Dialog.Body className="pb-0">
        {data ? (
          <div className="bg-grey-900 rounded-2xl p-12 text-[#625851]">
            <div className="bg-grey-900 p-120 rounded-2xl">
              <div className="h-fit-content relative mx-auto mt-4 flex min-h-[350px] w-full flex-col items-center overflow-hidden rounded-2xl border-[4px] border-[#63605D] bg-[#F8F7F4] px-6 py-4">
                <div className="inset-0 z-0 aspect-square w-5/12 rounded-lg bg-[#c6c6c3]">
                  {data?.imageResourceId ? (
                    <img
                      alt="group"
                      className="inset-0 z-0 h-full w-full rounded-lg object-cover object-center"
                      src={cloudinary
                        .image(data?.imageResourceId)
                        .resize(Resize.scale().width(700))
                        .toURL()}
                    />
                  ) : (
                    <div className="h-full w-full text-black"></div>
                  )}
                </div>
                <Heading className="mb-4 mt-4 px-4 text-center text-lg">
                  {data?.name || 'Your group name'}
                </Heading>
                <div className="mb-12 text-xs">
                  Created on {data?.createdAt || 'when you created the group'}
                </div>
                <div className="c mb-16 flex w-full flex-col rounded-2xl bg-white p-4">
                  <Text className="mb-2 text-xs">Group Description</Text>
                  <Text aria-label="preview-group-description">
                    {data?.description || 'when you created the group'}
                  </Text>
                </div>
                <Button className="flex w-full justify-center" type="primary">
                  JOIN GROUP
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-1 flex-col items-center justify-center">
            <Spin />
          </div>
        )}
      </Dialog.Body>
      <div className="px-8">
        <Divider />
      </div>
      <Dialog.Footer className="justify-start">
        <Button
          icon={<Icon name="chevron-left" />}
          onClick={() => {
            searchNavigate({ view: GroupFormTabs.Form });
          }}>
          Edit
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
