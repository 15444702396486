import { useFetcher, useSearchParams } from 'react-router';
import {
  TextField,
  TextAreaField,
  Dialog,
  Select,
  Item,
  FieldDescription,
  DatePicker,
} from '@venncity/block';
import { Button, Switch } from '@venncity/venn-ds';
import { isEmpty } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValueOf } from 'type-fest';
import BuildingsTagField from '~/components/buildings-tag-field';
import type { DiscussionsFormInitialValuesDTO } from '~/dto/discussions-form-dto';
import {
  DiscussionAudienceSelection,
  DiscussionFormAction,
  DiscussionFormTabs,
} from '~/dto/discussions-form-dto';
import { useAppLoaderData } from '~/utils/common';
import useScrollToError from '~/utils/hooks/useScrollToError';
import type { ActionResponseBody } from '~/utils/http.server';
import { GlobalSearchParam, useSearchNavigate } from '~/utils/search-params';
import { ImageUpload } from '../../../../../../components/image-upload';
import { CommunityCombobox } from '../community-combobox';

interface DiscussionFormProps {
  discussionId?: string;
  initialValues?: DiscussionsFormInitialValuesDTO;
  title: string;
}

export default function DiscussionForm({
  discussionId,
  initialValues,
  title,
}: DiscussionFormProps) {
  const dialogRef = useRef<HTMLElement>(null);
  const fetcher = useFetcher<ActionResponseBody<{ id: string }>>();
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();
  const { t } = useTranslation(['discussion-form']);
  const communityId = searchParams.get(GlobalSearchParam.CommunityId);
  const [poll, setPoll] = useState(initialValues?.poll);
  const published = Boolean(Number(searchParams.get('published')) || initialValues?.published);
  const isNewDiscussion = !discussionId;
  const pollReadonly = !!discussionId;
  const audience =
    searchParams.get('audience') ||
    initialValues?.audience ||
    DiscussionAudienceSelection.ALL_BUILDINGS;
  const anyFetchersArePending =
    fetcher.formMethod === 'POST' && ['loading', 'submitting'].includes(fetcher.state);

  const isSaving =
    anyFetchersArePending && fetcher.formData?.get('action') === DiscussionFormAction.Save;
  const isPreviewing =
    anyFetchersArePending && fetcher.formData?.get('action') === DiscussionFormAction.Preview;

  useEffect(() => {
    if (fetcher.state === 'loading' && !isEmpty(fetcher.data?.data)) {
      const action = fetcher.formData?.get('action') as ValueOf<typeof DiscussionFormAction>;
      if (action === DiscussionFormAction.Preview) {
        searchNavigate(
          { view: DiscussionFormTabs.Preview, discussionId: fetcher.data?.data?.id },
          { preventScrollReset: true },
        );
      } else {
        searchNavigate(
          {
            view: undefined,
            discussionId: undefined,
            dialogType: undefined,
            audience: undefined,
            poll: undefined,
          },
          { preventScrollReset: true },
        );
      }
    }
  }, [fetcher.data, fetcher.formData, fetcher.state, searchNavigate]);

  useScrollToError(dialogRef, fetcher.data?.errors || {});

  return (
    <Dialog ref={dialogRef}>
      <Dialog.Header title={title} />
      <fetcher.Form
        action={
          discussionId
            ? `/resources/discussions/${discussionId}/edit`
            : '/resources/discussions/new'
        }
        className="flex flex-1 flex-col"
        key={initialValues?.text}
        method="POST">
        <Dialog.Body>
          <TextAreaField
            defaultValue={initialValues?.text}
            description={t('description.description')}
            errorMessage={fetcher.data?.errors?.text}
            id="text"
            label={t('description.label')}
            name="text"
          />
          <CommunityCombobox className={discussionId ? 'hidden' : ''} />
          <div className="space-y-8">
            <Select
              defaultSelectedKey={audience}
              description={t('audience.description')}
              items={[
                { value: DiscussionAudienceSelection.ALL_BUILDINGS, text: t('audience.all') },
                { value: DiscussionAudienceSelection.BUILDINGS, text: t('audience.specific') },
              ]}
              label={t('audience.label')}
              name="audience"
              onSelectionChange={(value) => searchNavigate({ audience: value })}
              placeholder={t('audience.placeholder')}>
              {(item) => <Item id={item.value}>{item.text}</Item>}
            </Select>
            {audience === DiscussionAudienceSelection.BUILDINGS && (
              <BuildingsTagField
                communityId={communityId || ''}
                defaultValueFull={initialValues?.buildings}
                errorMessage={fetcher.data?.errors?.buildings}
                key={`buildings-for-${communityId}`}
                label={t('buildings.label')}
                placeholder={t('buildings.placeholder')}
              />
            )}
          </div>
          <ImageUpload
            defaultValue={initialValues?.imagePublicId}
            description={t('upload-image.description')}
            errorMessage={fetcher.data?.errors?.image}
            label={t('upload-image.label')}
            name="image"
          />
          <div>
            <Switch defaultChecked={published} id="published" label={t('published.label')} />
            <FieldDescription>{t('published.description')}</FieldDescription>
          </div>

          <Switch
            checked={poll}
            disabled={pollReadonly}
            id="poll"
            label={t('poll.label')}
            onChange={(value) => {
              setPoll(value);
            }}
          />

          {poll && <input defaultValue="on" name="poll" type="hidden" />}
          {poll && (
            <PollOptions
              defaultEndDate={initialValues?.endDate}
              defaultOptions={initialValues?.options}
              errorMessages={fetcher.data?.errors}
            />
          )}
        </Dialog.Body>
        <Dialog.Separator />
        <Dialog.Footer className="justify-between">
          <Button
            disabled={anyFetchersArePending}
            formNoValidate={true}
            htmlType="submit"
            loading={isPreviewing}
            name="action"
            value={DiscussionFormAction.Preview}>
            {t('cta.preview')}
          </Button>
          <Button
            disabled={anyFetchersArePending}
            htmlType="submit"
            loading={isSaving}
            name="action"
            type="primary"
            value={DiscussionFormAction.Save}>
            {isNewDiscussion ? t('cta.save') : t('cta.update')}
          </Button>
        </Dialog.Footer>
      </fetcher.Form>
    </Dialog>
  );
}

const PollOption = ({
  index,
  defaultValue,
  errorMessage,
}: {
  index: number;
  defaultValue?: string;
  errorMessage?: string;
}) => {
  const { t } = useTranslation(['discussion-form']);

  return (
    <TextField
      className="flex flex-col"
      defaultValue={defaultValue}
      description="Maximum 24 characters"
      errorMessage={errorMessage}
      id={`option${index}`}
      label={t('poll-option.index', { index: index + 1 })}
      maxLength={24}
      name="options"
    />
  );
};

const PollOptions = ({
  defaultOptions,
  defaultEndDate,
  errorMessages,
}: {
  defaultOptions?: Array<string>;
  defaultEndDate?: string;
  errorMessages?: Record<string, string[] | undefined>;
}) => {
  const { t } = useTranslation(['discussion-form']);
  const appLoaderData = useAppLoaderData();
  const timezone = appLoaderData.selectedCommunity.timezone;

  return (
    <>
      <PollOption
        defaultValue={defaultOptions?.[0]}
        errorMessage={errorMessages?.options && errorMessages?.options[0]}
        index={0}
      />
      <PollOption defaultValue={defaultOptions?.[1]} index={1} />
      <PollOption defaultValue={defaultOptions?.[2]} index={2} />
      <PollOption defaultValue={defaultOptions?.[3]} index={3} />
      <DatePicker
        defaultValue={defaultEndDate}
        description={t('poll-options.description')}
        errorMessage={errorMessages?.endDate}
        id="endDate"
        label={t('poll-options.label')}
        name="endDate"
        timezone={timezone!}
      />
    </>
  );
};

export const handle = {
  i18n: ['discussion-form'],
};
