import type { ValueOf } from 'type-fest';
import type { BuildingResourceDTO } from '~/dto/building-resource-dto';
import type { UserResourceDTO } from './user-resource-dto';

export const TicketType = {
  Free: 'FREE',
  Paid: 'Paid',
} as const;

export const EventFormAudienceType = {
  EntirePortfolio: 'ENTIRE_PORTFOLIO',
  Buildings: 'BUILDINGS',
} as const;

export const EventCategoryType = {
  Community: 'community',
  FitnessClasses: 'Fitness Classes',
  Neighborhood: 'neighborhood',
} as const;

export interface EventFormDTO {
  id: string;
  name: string;
  description?: string;
  category?: string;
  startDate?: string;
  endDate?: string;
  image: string;
  place: string;
  virtualLink?: string;
  audience?: ValueOf<typeof EventFormAudienceType>;
  buildings?: BuildingResourceDTO[];
  ticketType?: ValueOf<typeof TicketType>;
  ticketMax?: number;
  ticketPrice?: number;
  isPublished: boolean;
  isResidentHosted?: boolean;
  residentHosts?: UserResourceDTO[];
  communityIds: string[];
  shouldGenerateQRCode?: boolean;
  admissionFeeType?: ValueOf<typeof AdmissionFeeType>;
}

export const EventFormTabs = {
  Form: 'form',
  Preview: 'preview',
} as const;

export const EventFormAction = {
  Draft: 'draft',
  Publish: 'publish',
  Preview: 'preview',
} as const;

export const EventFormFields = {
  Name: 'name',
  Category: 'category',
  Description: 'description',
  StartDate: 'start-date',
  EndDate: 'end-date',
  Image: 'image',
  Place: 'place',
  VirtualLink: 'virtual-link',
  Audience: 'audience',
  Buildings: 'buildings',
  TicketType: 'ticket-type',
  TicketMax: 'ticket-max',
  TicketPrice: 'ticket-price',
  ResidentHosted: 'resident-hosted',
  ResidentHosts: 'resident-hosts',
  Action: 'action',
  ShouldGenerateQRCode: 'should-generate-qr-code',
  AdmissionFeeType: 'admission-fee-type',
} as const;

export const AdmissionFeeType = {
  None: 'NONE',
  Free: 'FREE',
  Complimentary: 'COMPLIMENTARY',
} as const;
