import {
  BottomSheet,
  DialogTrigger,
  Icon,
  Popover,
  Pressable,
  Text,
  SearchField,
  Item,
} from '@venncity/block';
import { listBoxTriggerIcon } from '@venncity/block/electrons/list-box';
import axios from 'axios';
import { debounce, isEmpty, isFunction } from 'lodash-es';
import React from 'react';
import { useFilter } from 'react-aria';
import { ListBox, type GridListProps } from 'react-aria-components';
import { useAsyncList } from 'react-stately';
import { type GroupResourceDTO } from '~/dto/group-resource-dto';
import { EmptyState } from '../helpers/empty-state';
import { useIsMobile } from '../hooks/useIsMobile';

export function InterestGroupsSelector({
  onSelectionChange,
  defaultSelectedKey = '',
  communityIds,
}: {
  onSelectionChange?: (value: GroupResourceDTO[] | null) => void;
  defaultSelectedKey?: string;
  communityIds: string[];
}) {
  const isMobile = useIsMobile();
  const [searchText, setSearchText] = React.useState('');

  const list = useAsyncList<GroupResourceDTO>({
    async load() {
      const { data } = await axios.get(
        `/resources/groups?${communityIds.map((id) => `community-id=${id}`).join('&')}&isPublic=true`,
      );
      return { items: data || [] };
    },
  });

  const filterSelectedKey = defaultSelectedKey
    ? list.items.filter((item) => item.id === defaultSelectedKey)
    : [];
  const [selectedKey, setSelectedKey] = React.useState<string[]>(
    defaultSelectedKey && filterSelectedKey.length > 0
      ? [
          JSON.stringify({
            id: filterSelectedKey[0]?.id,
            name: filterSelectedKey[0]?.name,
            memberCount: filterSelectedKey[0]?.memberCount,
          }),
        ]
      : [],
  );

  const selectedItem = selectedKey?.map((item) => JSON.parse(item.toString()) as GroupResourceDTO);

  const { contains } = useFilter({
    sensitivity: 'base',
  });

  const filteredItems = React.useMemo(() => {
    if (!list.items) return [];

    return list?.items?.filter((item) => contains(item?.name || '', searchText || ''));
  }, [contains, list.items, searchText]);

  const [isOpen, setIsOpen] = React.useState(false);
  const [BottomSheetIsOpen, setBottomSheetIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (defaultSelectedKey) {
      setSelectedKey([
        JSON.stringify({
          id: filterSelectedKey[0]?.id,
          name: filterSelectedKey[0]?.name,
          memberCount: filterSelectedKey[0]?.memberCount,
        }),
      ]);
      if (isFunction(onSelectionChange) && list.items.length > 0) {
        onSelectionChange(list.items);
      }
    }
  }, [list.items]);

  const shouldShowEmptyState = Boolean(
    list.items.length === 0 && !list.filterText && !list.isLoading,
  );

  const { searchField, content } = useCustomListBox({
    defaultSelectedKeys: selectedKey,
    items: filteredItems,
    onInputChange: debounce(setSearchText, 400, {
      maxWait: 1000,
      leading: false,
      trailing: true,
    }),
    onSelectionChange: (selection) => {
      setSelectedKey(Array.from(selection).map(String));
      isMobile ? setBottomSheetIsOpen(false) : setIsOpen(false);

      if (isFunction(onSelectionChange)) {
        onSelectionChange(JSON.parse(Array.from(selection)[0] as string));
      }
    },
    shouldShowEmptyState,
    isMobile,
  });

  return (
    <div aria-label="interest-group">
      {selectedItem && (
        <>
          <input name="interest-group-id" type="hidden" value={selectedItem[0]?.id || ''} />
          <input name="audience-size" type="hidden" value={selectedItem[0]?.memberCount || ''} />
        </>
      )}
      <div className="hidden lg:block">
        <DialogTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
          <ButtonTrigger
            isLoading={(defaultSelectedKey && isEmpty(list.items)) || false}
            key={list.items.toString()}
            selectedGroup={selectedItem[0]}
            setIsOpen={() => setIsOpen(!isOpen)}
          />
          <Popover className="shadow-10 w-[465px] outline-none" offset={2} placement="top end">
            <div className="space-y-2 overflow-y-auto">
              <div className="sticky top-0 w-full bg-white p-3">{searchField}</div>
              <div className="p-3 pt-0">{content}</div>
            </div>
          </Popover>
        </DialogTrigger>
      </div>
      <div className="lg:hidden">
        <DialogTrigger isOpen={BottomSheetIsOpen} onOpenChange={setBottomSheetIsOpen}>
          <ButtonTrigger
            isLoading={(defaultSelectedKey && isEmpty(list.items)) || false}
            key={list.items.toString()}
            selectedGroup={selectedItem[0]}
            setIsOpen={() => setIsOpen(!isOpen)}
          />
          <BottomSheet className="h-[95vh]">
            <BottomSheet.Header className="border-transparent">{searchField}</BottomSheet.Header>
            <BottomSheet.Body>{content}</BottomSheet.Body>
          </BottomSheet>
        </DialogTrigger>
      </div>
    </div>
  );
}

const useCustomListBox = ({
  onInputChange,
  items,
  shouldShowEmptyState,
  isMobile,
  ...props
}: GridListProps<GroupResourceDTO> & {
  onInputChange: (value: string) => void;
  shouldShowEmptyState?: boolean;
  isMobile: boolean;
}) => {
  const [value, setValue] = React.useState('');

  const searchField = (
    <SearchField
      aria-label="search field"
      className={isMobile ? '' : 'bg-white'}
      label="Search for a group..."
      onChange={(e) => {
        onInputChange(e);
        setValue(e);
      }}
      value={value}
    />
  );

  const content = shouldShowEmptyState ? (
    <EmptyState stateType="groups" />
  ) : items && Array.from(items).length < 1 ? (
    NoSearchResults(value)
  ) : (
    <>
      <ListBox
        aria-label={'interest-group-list'}
        className={isMobile ? 'p-2 pt-0' : 'h-full overflow-y-auto'}
        {...props}
        items={Array.from(items ?? [])}
        selectionMode="single">
        {(item) => (
          <Item id={JSON.stringify(item)} textValue={item.name || ''}>
            {item.name}
          </Item>
        )}
      </ListBox>
    </>
  );

  return { searchField, content };
};

function ButtonTrigger({
  setIsOpen,
  isLoading,
  selectedGroup,
}: {
  setIsOpen: (isOpen: boolean) => void;
  isLoading?: boolean;
  selectedGroup: { name: string; memberCount: number | null } | undefined;
}) {
  return (
    <Pressable
      aria-label="events selector"
      className="rac-pressed:bg-grey-50 w-full rounded-lg bg-white p-4 focus:outline-none"
      onPress={() => setIsOpen}>
      <div className="flex w-full items-center justify-between">
        {selectedGroup ? (
          <div className="flex flex-col gap-y-2 text-left">
            <Text>{selectedGroup?.name}</Text>
            <Text className={isLoading ? 'text-grey-400' : 'text-grey-600'}>
              {isLoading ? 'Loading...' : ` ${String(selectedGroup.memberCount || 0)} Residents`}
            </Text>
          </div>
        ) : (
          <Text className="text-grey-600">Select a group...</Text>
        )}
        <span className="text-grey-900 flex-shrink-0 transition-transform group-data-[expanded=true]:rotate-180">
          <Icon className={listBoxTriggerIcon()} name="chevron-down-large" />
        </span>
      </div>
    </Pressable>
  );
}

function NoSearchResults(value: string) {
  return (
    <div className="text-grey-900 flex flex-col gap-6 p-6 text-center">
      <Text>
        No results found for <b>&quot;{value}&quot;</b>
      </Text>
      <Text>Try adjusting your search to find what you&apos;re looking for</Text>
    </div>
  );
}
