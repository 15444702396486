import { useSearchParams } from 'react-router';
import { DialogTrigger, Drawer } from '@venncity/block';
import { GroupFormTabs } from '~/dto/group-form-dto';
import { useSearchNavigate } from '~/utils/search-params';
import EditGroupForm from './edit-group-form';
import { GroupPreview } from './group-preview';
import GroupsForm from './groups-form';

export function GroupFormDialog() {
  const [searchParams] = useSearchParams();
  const searchNavigate = useSearchNavigate();

  const groupId = searchParams.get('group-id');
  const view = searchParams.get('view') || GroupFormTabs.Form;

  return (
    <DialogTrigger
      defaultOpen
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          searchNavigate(
            {
              dialogType: undefined,
              groupId: undefined,
              view: undefined,
            },
            { preventScrollReset: true },
          );
        }
      }}>
      <Drawer className="flex flex-col overflow-hidden">
        <div className="flex min-h-0 flex-1 flex-col">
          {view === GroupFormTabs.Preview ? (
            <GroupPreview />
          ) : groupId ? (
            <EditGroupForm />
          ) : (
            <GroupsForm title="Create Group" />
          )}
        </div>
      </Drawer>
    </DialogTrigger>
  );
}
